






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component
export default class FileInput extends Vue {
  @Prop({ type: Array as () => File[] }) value!: File[];

  input($event: any) {
    console.log($event);
    this.$emit("input", $event.target.value);
  }
}
