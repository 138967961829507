//@ts-nocheck
import { extend, configure } from "vee-validate";
import { required, email, length, min } from "vee-validate/dist/rules";
import { setLocale, localize } from "@vee-validate/i18n";
import de from "@vee-validate/i18n/dist/locale/de.json";
//tslint-disable
extend("required", required);
extend("email", email);
extend("length", length);
extend("min", min);
extend("confirmed", {
  params: ["target"],

  validate(value, { target }) {
    return value === target;
  },
});

extend("verify_password", {
  message: `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and must be 8 characters long.`,
  validate: (value) => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    return strongRegex.test(value);
  },
});

configure({
  // Generates an English message locale generator
  generateMessage: localize({
    de,
  }),
});

setLocale("de");

// curl -sfL htt∂ps://get.k3s.io | sh -s - server --datastore-endpoint="mysql://k31265_rancher2:pLxr1\!74@tcp(46.38.249.89:3306)/k31265_rancher2"

// K3S_TOKEN=wxHp8qWnhegYKiIQzCkR6UZPRpA75a4T

// configure({
//   // this will be used to generate messages.
//   defaultMessage: (_, values) => i18n.t(`validations.${values._rule_}`, values)
// });
