import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/sass/overrides.sass";
Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#013194",
        secondary: "#b3d4fc",
        accent: "#b3d4fc",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});

export default vuetify;

import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify });
