import { cognito } from "@/store";
import { Getters } from "@/store/modules/cognito/cognito.types";
import LoggedInLayout from "./LoggedIn/Layout.vue";
import LoggedOutLayout from "./LoggedOut/Layout.vue";

import Vue, { CreateElement, VNode } from "vue";
export default Vue.extend({
  components: {
    LoggedInLayout,
    LoggedOutLayout,
  },
  computed: {
    ...cognito.mapGetters([Getters.isLoggedIn]),
  },
  render(createElement: CreateElement): VNode {
    if (this.isLoggedIn) {
      return createElement(LoggedInLayout);
    }
    return createElement(LoggedOutLayout);
  },
});
