<template>
  <v-container>
    <v-form>
      <base-material-card color="pink" light max-width="100%" class="px-5 py-3">
        <template #heading>
          <div class="text-center">
            <h1 class="display-2 font-weight-bold mb-2">
              {{ $t("signin") }}
            </h1>

            <!-- <v-btn class="ma-1" icon rel="noopener" target="_blank" large>
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="ma-1" icon rel="noopener" target="_blank" large>
              <v-icon>mdi-google-plus</v-icon>
            </v-btn> -->
          </div>
        </template>

        <v-row justify="center">
          <v-col cols="12">
            <!-- <span class="display-5 font-weight-light">{{ $t("classical") }}</span> -->
          </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center">
        <base-heading class="text-center" text="Sign in" sub="to continue with Centralize" />
      </v-row> -->
        <v-text-field class="mt-9" v-model="email" autofocus :label="$t('email') + '*'" autocomplete="username">
          <template #prepend>
            <v-icon>mdi-email</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          v-model="password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          class="mb-3"
          :label="$t('password')"
          name="password"
          @click:append="show = !show"
          autocomplete="current-password"
          autofocus
          v-on:keyup.enter="submit"
        >
          <template #prepend>
            <v-icon>mdi-lock</v-icon>
          </template>
        </v-text-field>

        <v-row align="center" align-content="space-between">
          <v-col cols="6">
            <base-text to="/auth/signup">{{ $t("createAccount") }}</base-text>
          </v-col>
          <v-col cols="6">
            <span class="ml-2">
              <base-text to="/auth/reset-password">{{ $t("resetPassword") }}</base-text>
            </span>
          </v-col>
          <v-col>
            <v-btn :disabled="!email || !password" color="primary" @click="submit" :loading="isLoading" rounded>
              {{ $t("signin") }}
            </v-btn>
          </v-col>
        </v-row>
      </base-material-card>
    </v-form>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "Email",
  data: () => ({
    show: false,
  }),
  methods: {
    ...mapActions("cognito", ["signInUser"]),
    ...mapMutations(["setEmail", "setPassword", "setIsLoading", "setSnackbar"]),
    async submit() {
      this.setIsLoading(true);
      try {
        await this.signInUser({
          username: this.email,
          password: this.password,
        });

        this.setSnackbar({
          type: "success",
          msg: `${this.$t("signinSuccess")} ${this.email}`,
        });

        this.setEmail("");
        this.$router.push("/");
      } catch (res) {
        this.setSnackbar({
          type: "error",
          msg: this.$t(res.code),
        });
      } finally {
        this.setIsLoading(false);
        this.setPassword("");
      }
    },
  },
  computed: {
    ...mapState(["isLoading"]),
    email: {
      get() {
        return this.$store.state.email;
      },
      set(val) {
        this.setEmail(val.toLowerCase());
      },
    },

    password: {
      get() {
        return this.$store.state.password;
      },
      set(val) {
        this.setPassword(val);
      },
    },
  },
});
</script>
