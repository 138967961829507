export default {
  ok: "OK",
  cancel: "Cancel",
  submit: "Send",
  title: "Titel",
  contractTitle: "Upload new Contract!",
  contractSubtitle: "Upload your first contract in order to save time and money",
  upload: "Upload",
  confirm: "Confirm",
  save: "Save",
  street: "Street",
  streetNumber: "Street Number",
  floor: "Level",
  stairs: "Additional Address Information",
  doorNumber: "Apartment",
  zip: "ZIP Code",
  city: "City",
  country: "Country",
  accept: "Please accept our",
  salutation: "Title",
  birthday: "Date of birth",
  personsInHousehold: "Persons in houshold",
  areaForHousehold: "Living space",
  agbAccept: "Terms and conditions",
  dsgAccept: "Privacy policy",
  password: "Password",
  toSignin: "Login",
  passwordConfirmation: "Confirm password",
  email: "Email",
  emailConfirmation: "Confirm Email",
  firstname: "First Name",
  lastname: "Last Name",
  price: "Costs",
  duration: "Contract period",
  classical: "or classic!",
  saveMoney: "Save money",
  saveMoneyText:
    "Centralize is designed to make your live easier. Save up to 60% of your regular contract costs with our free services",
  overview: "Keep an overview",
  overviewText: "Don't pay too much for your Contracts - We help you to keep an overview of them",
  organize: "Because we want to make your life easier!",
  organizeText: "You are just one click away from lower fixed costs",
  createAccount: "Register",
  resetPassword: "Reset password",
  resetPasswordSub: "We'll send you an email with a reset link shortly",
  backToLogin: "Back to login",
  next: "Next",
  null: "",
  undefined: "",
  register: "Register",
  signin: "Login",
  imprint: "Imprint",
  agb: "Terms and conditions",
  sepa: "SEPA",
  cancellation: "Cancellation policy",
  dsg: "Data privacy",
  dashboard: "Dashboard",
  home: "Dashboard",
  contracts: "Contracts",
  providers: "Provider",
  archive: "Archive",
  "create-contract": "Upload contract",
  "how-does-this-work": "How to use centralize?",
  contact: "Contact",
  logout: "Logout",
  search: "Search",
  profile: "Profil",
  "contract-detail": "Contract details",
  "contract-detail-tab": "Contract details",
  selectContractHeadline: "Please select a Contract!",
  selectContract: "Select Contract",
  selectChangeOption: "Select",
  contract: "Contract",
  details: "Details",
  status: "Status",
  changeOptions: "Switching options",
  registerHeadline: "Register now!",
  headline: "Your Contracts",
  archivedHeadline: "Archived Contracts",
  multiple: "Contracts",
  uploadMore: "Upload more Contracts!",
  totalCost: "Total costs",
  totalCostFromAll: "from all your Contracts",
  savings: "Savings",
  potentialSavings: "Potential savings",
  thisYear: "This year",
  contractState: "Status",
  provider: "Provider",
  editProvider: "Edit provider",
  edit: "Edit",
  delete: "Delete",
  createProvider: "Create provider",
  contractType: "Type of Contract",
  contractFiles: "Contract files",
  POWER: "Power",
  GAS: "Gas",
  totalPricePerYear: "Annual price",
  startDate: "Start date",
  createdAt: "Uploaded on",
  changeAutomatically: "Automatic switch",
  UID: "User",
  resultErrorHeadline: "Sorry. Something went wrong!",
  resultErrorText: "Please enter your email to receive another confirmation mail",
  resendConfirmation: "Resend confirmation",
  welcomeHeadline: "Wellcome",
  emailConfirmationText: "Thank you very much for your registration. Log in and benefit from centralize!",
  contractDetailTitle: "Contract details",
  previewHeadline: "Contract view",
  previewSubheadline: "You can see a preview of your uploaded Contract - just click to open it!",
  dataheadline: "Contract details",
  datasubheadline: "All details about your Contract at a glance",
  statusheadline: "Contract status",
  statussubheadline: "View the current status of your Contract here",
  changeoptionsheadline: "Switching options",
  changeoptionheadline: "Switching option",
  changeoptionssubheadline: "Please choose one of the following switching options, we take care of everything else!",
  "contract-archive": "Archive",
  "contract-create": "Upload Contract",
  tariffName: "Tariff",
  shippingAddress: "Shipping address",
  billingAddress: "Billing address",
  addChangeOption: "Add change option",
  submitChangeOptions: "Submit change option",
  economicalDecorations: "Green energy certificate",
  priceModel: "Pricing mode",
  usagePerYear: "Annual usage",
  pricePerUnit: "Price per unit",
  basePrice: "Energy price",
  networkCostPerYear: "Annual grid cost",
  firstYearBonus: "Bonus",
  paymentType: "Payment method",
  billingCycle: "Billing period",
  contractDuration: "Contract duration",
  cancellationPeriod: "Period of notice",
  singleInvoice: "Single invoice",
  comperaLink: "Compera Link",
  signinSuccess: "Successfully signed in user",
  registerSuccess: "Successfully registered! An email will be sent to shortly",
  archivedSuccess: "Contract archived",
  archiveContract: "archive",
  changeAutomatically_true: "Automatic change activated",
  changeAutomatically_false: "Automatic change deactivated",
  savingSuccess: "Saved!",
  savingError: "An error occured while saving your data",
  setContractChangedInProgress: "Vertragswechsel gestartet",
  UPLOADED: "Contract is being processed",
  CONTRACT_DATA_ADDED: "Contract processing succeeded",
  CHANGE_OPTIONS_ADDED: "Switching options available!",
  CHANGE_OPTION_SELECTED: "Switching option selected",
  CONTRACT_CHANGE_IN_PROGRESS: "Contract switch is in progress!",
  CONTRACT_CHANGED: "Contract successfully switched!",
  CONTRACT_CHANGE_FAILED: "Contract switch failed. You will receive an email with more information!",
  TRANSITIONED_CONTRACT: "Your Contract switch was successful. You will receive an email with more information!",
  ARCHIVED: "Contract is archived and will not be reflected on your dashboard anymore",
  UsernameExistsException: "User already exists.",
  UserNotConfirmedException: "Please confirm your email address",
  NotAuthorizedException: "Incorrect username or password.",
  ExpiredCodeException: "Confirmation code expired.",
  CodeMismatchException: "Ivalid code.",
  male: "Dear Sir",
  female: "Dear Madam",
  company: "Dear colleagues",
  defaultAddress: "Default address",
  defaultAddressSettings: "Default address settings are changed globally (effects all following contracts)",
  finish: "Finish",
  previous: "Previous",
  addAddress: "Add address",
  netPrice: "Net prices",
  noContractsYetHeader: "Looks like you just signed up!",
  noContractsYetBody: "you don't have any contracts uploaded what would you like to do?",
  createContractSuccess:
    "Thanks! We received your documents and will come back to you in the next 24h. Please check our email and spam for further instructions.",
  understandLabel: "OK",
  attentionLabel: "Attention!",
  changeAutomaticallyActivated:
    "Automated contract change is activated. Centralize will check for the best available tariff for you!",
  addContractDocuments: "Add document",
  contractChangeCheckbox: `I accept {agb}, the {cancellation}, the {dsg}, the {sepa} of {providerName}
                and also take {centralizeDsg} and {centralizeAgb} of centralize into account. 
                I agree to share my data with {providerName} for further processing.`,
  setChangeInProgress: "Initiate change",
  AT_NORM: "AT Norm",
  GLOBAL_2000_NORM: "Global 2000",
  INVOICE: "Rechnung",
  DIRECT_DEBIT: "Bankeinzug",
  STANDARD: "Standard",
  PRICE_GUARANTEE: "Preis Garantie",
  FLOATING: "Floating",
  MONTHLY: "Monatlich",
  QUATERLY: "QUATERLY",
  BIANNUALY: "BIANNUALY",
  ANNUALLY: "ANNUALLY",
  myRequest: "Meine Anfrage",
  requestMessage:
    "Bitte lass uns wissen, wie wir dir helfen koennen und schick uns eine Nachricht und deine Fragen hier:",
  requestImprovement: "Du weißt wir sind neu, daher freuen wir uns auch über Verbesserungsvorschläge",
  requestSuccess: "Vielen Dank für deine Nachricht! Wir melden uns so schnell wie möglich bei dir.",
  toDashboard: "zurück zum Dashboard",
  yourPhoneNumber: "Deine Telefonnummer",
};
