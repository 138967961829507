<template>
  <provider-dashboard />
</template>

<script>
import Vue from "vue";
import ProviderDashboard from "@/components/ProviderDashboard";
export default Vue.extend({
  components: {
    ProviderDashboard,
  },
});
</script>

<style></style>
