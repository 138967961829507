export enum Actions {
  load = "load",
  delete = "delete",
  loadAddressByUser = "loadAddressByUser",
  createAddress = "createAddress",
  createAddressForUser = "createAddressForUser",
  updateAddress = "updateAddress",
  deleteAddress = "deleteAddress",
}

export enum Getters {
  adresses = "adresses",
  progress = "progress",
  primaryBillingAddress = "primaryBillingAddress",
  primaryShippingAddress = "primaryShippingAddress",
}

export interface AddressState {
  data: Address[];
  progress: number;
}

export interface Address {
  ID?: string;
  street?: string;
  streetNumber?: string;
  stairs?: string;
  floor?: string;
  doorNumber?: string;
  zip?: string;
  city?: string;
  country?: string;
  primaryBillingAddress?: boolean;
  primaryShippingAddress?: boolean;
}
