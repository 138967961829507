import * as AWS from "aws-sdk";
import Vue from "vue";
import { Module } from "vuex";
import { addressResource } from "@/api";
import { AddressState, Actions, Address, Getters } from "./address.types";
import { AxiosResponse } from "axios";
import { set, add } from "../helpers";

export const addressModule: Module<AddressState, any> = {
  namespaced: true,
  state: { data: [], progress: 0 },
  mutations: {
    data: set("data"),
    add: add("data"),
    progress: set("progress"),
    update: (state: AddressState, payload: Address) => {
      const index = state.data.findIndex((add) => add.ID === payload.ID);
      if (index != -1) {
        state.data[index] = payload;
      }
    },
    remove: (state: AddressState, payload: Address) => {
      const index = state.data.findIndex((add) => add.ID === payload.ID);
      if (index != -1) {
        Vue.delete(state.data, index);
      }
    },
  },
  actions: {
    async load({ commit }) {
      const addresses = (await addressResource.get("")).data;
      commit("data", addresses);
      return addresses;
    },

    async [Actions.loadAddressByUser]({ commit, rootGetters }, userId) {
      const addresses = (await addressResource.get(`/${userId}`)).data;
      commit("data", addresses);
      return addresses;
    },

    async [Actions.updateAddress]({ dispatch, commit }, payload: any) {
      const address = (await addressResource.put<Address, AxiosResponse<Address>>("", payload)).data;
      commit("update", address);
      return address;
    },

    async [Actions.delete]({ dispatch, commit }, payload: Address) {
      await addressResource.delete<Address, AxiosResponse<void>>(`/${payload.ID}`);
      commit("remove", payload);
      return payload;
    },

    async [Actions.createAddress]({ dispatch, commit }, payload: any) {
      const address = (await addressResource.post<Address, AxiosResponse<Address>>("", payload)).data;
      commit("add", address);
      return address;
    },

    async [Actions.createAddressForUser]({ dispatch, commit }, payload: any) {
      const address = (
        await addressResource.post<Address, AxiosResponse<Address>>(`/${payload.userId}`, payload.address)
      ).data;
      commit("add", address);
      return address;
    },
  },
  getters: {
    [Getters.adresses]: (state: AddressState) => state.data,
    [Getters.progress]: (state: AddressState) => state.progress,
    [Getters.primaryBillingAddress]: (state: AddressState) => state.data.find((a) => a.primaryBillingAddress),
    [Getters.primaryShippingAddress]: (state: AddressState) => state.data.find((a) => a.primaryShippingAddress),
  },
};
