























































import * as AWS from "aws-sdk";
import { cognitoNamespace } from "@/store";
import { Getters as CognitoGetters } from "@/store/modules/cognito/cognito.types";
import Component from "vue-class-component";
import { ContractAware } from "../../mixins/ContractAware";
import { DocumentDto } from "../../store/modules/documents.dto";
import { timeout } from "@/helpers";
import { Watch } from "vue-property-decorator";

@Component({})
export default class ContractPreview extends ContractAware {
  @cognitoNamespace.Getter(CognitoGetters.isAdminOrCentralizeUser) isAdminOrCentralizeUser!: boolean;

  @cognitoNamespace.Getter(CognitoGetters.userForId)
  userForId!: (uid: string) => any;

  downloadUrls: DocumentDto & { url: string }[] = [];
  files: any[] = [];
  fileDialog: boolean = false;
  loadingFileDialog: boolean = false;
  deletingFile: string = "";

  async submitFileDialog() {
    this.loadingFileDialog = true;
    try {
      await this.addFiles({ contract: this.contract, files: this.files, user: this.userForId(this.contract.UID!) });
    } finally {
      this.loadingFileDialog = false;
    }
    await this.updateDownloadUrls();
    await timeout(1000);
    this.fileDialog = false;
    this.files = [];
  }

  async deleteDocument(file: DocumentDto) {
    this.deletingFile = file.path!;
    try {
      await this.deleteFile({ contract: this.contract, file: file });
    } finally {
      this.deletingFile = "";
    }

    await this.updateDownloadUrls();
  }

  async created() {
    await this.updateDownloadUrls();
  }

  async updateDownloadUrls() {
    this.downloadUrls = [];
    if (this.contract.s3Path) {
      const url = await this.getDownloadUrl(this.contract);
      this.downloadUrls = [{ url }];
    } else {
      for (let doc of this.contract.documents!) {
        this.downloadUrls.push({
          url: await this.getDocumentUrl(doc),
          ...doc,
        });
      }
    }
  }

  @Watch("$route.params.id", { deep: true }) async routeWatcher(prev: string, cur: string) {
    if (prev !== cur) {
      await this.updateDownloadUrls();
    }
  }
}
