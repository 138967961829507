import Vue from "vue";
import Component from "vue-class-component";
import { ActionMethod } from "vuex";
import { Action } from "vuex-class";
import { contractsNamespace } from "../store";
import {
  Actions,
  Contract,
  ContractPriceModel,
  ContractType,
  EconomicalDecoration,
  Getters,
  PaymentType,
} from "../store/modules/contract.types";
import { DocumentDto } from "../store/modules/documents.dto";

@Component
export class ContractAware extends Vue {
  get contract() {
    return this.contractById(this.editContractId);
  }

  @contractsNamespace.State("editContractId")
  editContractId!: string;
  @contractsNamespace.Getter(Getters.contractById)
  contractById!: (id: string) => Contract;
  @contractsNamespace.Getter(Getters.currentContract)
  currentContract!: Contract;
  @contractsNamespace.Getter(Getters.contractStateIndex)
  contractStateIndex!: number;

  @contractsNamespace.Action(Actions.getDownloadUrl)
  getDownloadUrl!: (contract: Contract) => Promise<string>;
  @contractsNamespace.Action(Actions.getDocumentUrl)
  getDocumentUrl!: (document: DocumentDto) => Promise<string>;
  @contractsNamespace.Action(Actions.updateChangeOptions)
  updateChangeOptions!: ActionMethod;
  @contractsNamespace.Action(Actions.deleteChangeOption)
  deleteChangeOption!: ActionMethod;
  @contractsNamespace.Action(Actions.selectChangeOption)
  selectChangeOption!: ActionMethod;
  @contractsNamespace.Action(Actions.setContractState)
  setContractState!: ActionMethod;
  @contractsNamespace.Action(Actions.update)
  updateContract!: ActionMethod;
  @contractsNamespace.Action(Actions.addFiles)
  addFiles!: ActionMethod;

  @contractsNamespace.Action(Actions.deleteFile)
  deleteFile!: (payload: { contract: Contract; file: DocumentDto }) => Promise<Contract>;
  @contractsNamespace.Action(Actions.unlock)
  unlockContract!: ActionMethod;
  @contractsNamespace.Action(Actions.addAdditionalMeter)
  addAdditionalMeter!: (contract: Contract) => void;
  @contractsNamespace.Action(Actions.removeAdditionalMeter)
  removeAdditionalMeter!: (payload: { contract: Contract; index: number }) => void;

  get contractTypes(): { text: string; value: ContractType }[] {
    return Object.values(ContractType).map((contractType) => {
      return {
        text: this.$t(contractType).toString(),
        value: contractType,
      };
    });
  }

  get priceModels(): { text: string; value: ContractPriceModel }[] {
    return Object.values(ContractPriceModel).map((priceModel) => {
      return {
        text: this.$t(priceModel).toString(),
        value: priceModel,
      };
    });
  }

  get paymentTypes(): { text: string; value: PaymentType }[] {
    return Object.values(PaymentType).map((paymentType) => {
      return {
        text: this.$t(paymentType).toString(),
        value: paymentType,
      };
    });
  }

  get ecoDecorations(): { text: string; value: EconomicalDecoration }[] {
    return Object.values(EconomicalDecoration).map((decoration) => {
      return {
        text: this.$t(decoration).toString(),
        value: decoration,
      };
    });
  }
}
