<template>
  <v-container>
    <v-row id="login" justify="center" style="width: 100%">
      <v-card class="elevation-4" style="width: 100%">
        <v-card-title>
          <base-subheading>{{ $t("agb") }}</base-subheading>
        </v-card-title>

        <v-card-text>
          <p>
            Allgemeine Geschäftsbedingungen der Centralize GmbH ("AGB")
          </p>
          <p>
            Stand: [22.03.2021]
          </p>
          <h3>
            1. GELTUNGSBEREICH
          </h3>
          <p>
            1.1. Die Centralize GmbH, Untere Landstraße 27, 3500 Krems, Österreich ("centralize", "wir", "uns") betreibt
            unter dem Namen “centralize“ den Vertrags-, Dokumentenmanagement und Energieanbieterwechselservice unter der
            Website <a href="http://www.centralize.at">www.centralize.at</a>
            ("Website").
          </p>
          <p>
            1.2. Diese AGBs gelten für die Inanspruchnahme der centralize Serviceleistungen durch Registrierung auf der
            Website. Mit Registrierung auf centralize und Anklicken der entsprechenden Checkbox erklärt sich der Kunde
            mit diesen AGB einverstanden. Der Kunde erhält zur Verifizierung des Vertragsabschlusses per E-Mail einen
            Aktivierungslink zugeschickt. Diese E-Mail enthält alle erforderlichen Informationen zu den
            Vertragskonditionen und die Weiterleitung zu den centralize AGB.
          </p>
          <p>
            1.3. Vertragsabschlüsse sind nur zu diesen Bedingungen möglich. Abweichenden, entgegenstehenden,
            einschränkenden oder ergänzenden Geschäftsbedingungen des Kunden müssen wir ausdrücklich schriftlich
            zustimmen, damit diese im Einzelfall Vertragsbestandteil werden. Insbesondere gelten
            Vertragserfüllungshandlungen von uns nicht als Zustimmung zu etwaigen von unseren AGB abweichenden
            Bestimmungen.
          </p>
          <p>
            1.4. Änderungen der centralize AGB werden dem Kunden 4 Wochen vor Inkrafttreten per E-Mail an die
            bekanntgegebene E-Mail Adresse des Kunden mitgeteilt. Sofern der Kunde der Änderung nicht innerhalb von 14
            Kalendertagen nach Erhalt der Nachricht – die explizit auf die Wirkung eines etwaigen Schweigens hinweist –
            ausdrücklich widerspricht, gilt die Änderung als angenommen. Im Fall des Widerspruchs gelten die alten AGB
            weiter; wir behalten uns für diesen Fall vor, den Vertrag mit dem Kunden ordentlich zu kündigen.
          </p>
          <p>
            1.5. centralize wird eine Gegenüberstellung der betroffenen Änderungen der Bestimmungen in den AGB sowie die
            vollständige Fassung der neuen AGB auf seiner Homepage veröffentlichen.
          </p>
          <h3>
            2. REGISTRIERUNG UND VERTRAGSABSCHLUSS
          </h3>
          <p>
            2.1. Der Kunde muss mindestens 18 Jahre alt und damit voll geschäftsfähig sein. Die Inanspruchnahme unserer
            Serviceleistungen ist nur mit Registrierung über die Website www.centralize.at möglich.
          </p>
          <p>
            2.2. Der Kunde hat bei der Registrierung grundlegende Daten zur Identifikation (zB Vorname, Nachname,
            E-Mailadresse) anzugeben. Alle Daten zur Identifikation (zB Vorname, Nachname, Adresse inklusive aller
            Zusätze, Telefonnummer, E-Mailadresse, IBAN, BIC) können über das Userprofil ergänzt und geändert werden,
            ansonsten werden diese soweit wie möglich bei Upload eines Vertrages / Dokuments durch centralize ausgelesen
            und ergänzt. Die Daten zur Ermittlung des für ihn günstigsten Energietarifs (zB Angabe des aktuellen
            Energieanbieters, des aktuellen Tarifs, etwaiger Vertragsbindungen bzw. Hochladen einer Energierechnung,
            gewünschte Energieart, Stromzählertyp, Zählpunktbezeichnung, Verbrauch, aktueller Energieanbieter, aktueller
            Energietarif) werden nach Upload durch centralize ausgelesen. Weiters hat uns der Kunde entsprechend zur
            Vornahme der Energieanbieterwechsel in dem in Pkt 3 angeführten Umfang zu bevollmächtigen sowie ein
            SEPA-Lastschriftmandat zum Einzug der Servicegebühr durch uns zu erteilen.
            <br />
            2.3. Mit Absenden der Registrierung gibt der Kunde sein Angebot zum Abschluss eines Servicevertrages für
            eine unbestimmte Dauer ab und bestätigt die Richtigkeit seiner Angaben. Nach Absenden der Registrierung
            erhält der Kunde eine automatisierte Bestätigungsmail, die noch keine Annahme der Registrierung und des
            Vertragsabschlusses durch uns darstellt.
            <br />
            2.4. Die Annahme durch uns erfolgt über eine gesonderte Bestätigung per E-Mail.
            <br />
            2.5. Wie behalten uns zudem das Recht vor, einen Antrag auf Registrierung und somit auch den
            Vertragsabschluss ohne Angabe von Gründen abzulehnen.
            <br />
            2.6. Der Kunde ist zur Geheimhaltung seiner Zugangsdaten verantwortlich. Wir können nur überprüfen, ob das
            Zugangspasswort mit einer ordnungsgemäß freigeschalteten Kundenberechtigung übereinstimmt. Eine
            weitergehende Überprüfungspflicht trifft uns nicht.
            <br />
            2.7. Mit Wirksamwerden der Kündigung oder des Widerrufs des Vertrages erlischt auch die Registrierung.
          </p>
          <h3>
            3.VOLLMACHT
          </h3>
          <p>
            3.1. Allgemeines
            <br />
            Der Kunde bevollmächtigt die Centralize GmbH ("centrlaize"), ihn im Rahmen des zwischen ihm und centralize
            geschlossenen Vertrages umfassend bei allen relevanten Maßnahmen mit und gegenüber Energieanbietern und
            Netzbetreibern, sowie sonstigen relevanten Dritten (z.B. E-Control, Statistik Austria, relevante
            Interessensvertretungen, weitere relevante Marktteilnehmer, Online-Preisvergleichs-Plattformen) in seinem
            Namen und auf seine Rechnung zu vertreten (Erklärungs- und Empfangsvollmacht), um für ihn wiederkehrend
            bestehende Energielieferverträge für Strom und Gas, sowie Mobilfunk- und Internetverträge zu kündigen und
            neue abzuschließen sowie laufende Verträge abzuwickeln und die dafür erforderlichen Erklärungen und
            Vereinbarungen gegenüber Energieanbietern, Online-Preisvergleichs-Plattformen, Netzbetreibern,
            Mobilfunkanbietern, Internetanbietern, Banken und sonstigen Beteiligten abzugeben sowie alle entsprechenden
            Erklärungen, Dokumente und Informationen des jeweiligen Gegenübers für den Kunden in Empfang zu nehmen.
          </p>
          <p>
            Darüber hinaus bevollmächtigt der Kunde centralize, einem allfälligen neuen Anbieter eine Untervollmacht
            erteilen zu können, damit dieser den Wechselprozess durchführen, also insbesondere auch die Kündigung
            gegenüber dem Altlieferanten erklären und vollziehen kann, als auch den Netzbetreiber des Kunden
            entsprechend darüber zu informieren.
          </p>
          <p>
            Mit der Erteilung der Vollmacht gibt der Kunde seine ausdrückliche Willenserklärung zum
            Energieanbieterwechsel zum Ausdruck.
          </p>
          <p>
            Die vorliegende Vollmacht umfasst darüber hinaus insbesondere:
          </p>
          <ul>
            <li>
              Vertragserklärungen, die für einen Strom-, Gas-, Mobilfunk- und Internetanbieterwechsel erforderlich sind
              rechtsverbindlich für den Kunden auf Basis der jeweils gültigen Allgemeinen Geschäftsbedingungen von
              Netzbetreiber und/oder des Energieanbieters oder des Mobilfunkanbieters oder des Internetanbieters sowie
              von Online-Preisvergleichs-Plattformen abzugeben und Erklärungen und Informationen für den Kunden
              entgegenzunehmen;
            </li>
            <li>
              Das Ausfüllen von Online-Formularen und ähnlichen internet- oder app-basierten Formularen und
              Eingabemasken und den Abschluss von Online-Wechselvorgängen beim Netzbetreiber und/oder Energieanbieter
              oder des Mobilfunkanbieters oder des Internetanbieters oder einer Online-Preisvergleichs-Plattformen im
              Namen des Kunden;
            </li>
            <li>
              Erstellung eines Online-Benutzer-Kontos beim Energieanbieter, Mobilfunkanbieter oder Internetanbieter im
              Namen des Kunden
            </li>
            <li>
              Erklärungen gegenüber dem Netzbetreiber und/oder neuen Energielieferanten oder des Mobilfunkanbieters oder
              des Internetanbieters über das Verlangen der vorzeitigen Vertragserfüllung vor Ablauf der gesetzlichen
              Widerrufsfrist des Kunden von 14 Tagen (festgehalten wird, dass den Kunden im Fall einer solchen Erklärung
              eine Zahlungspflicht für von ihm bis zur Erklärung des Rücktritts in Anspruch genommene Leistungen des
              Energielieferanten, Mobilfunkanbieters oder Internetanbieters trifft);
            </li>
            <li>
              Die Erteilung von Untervollmachten an relevante Dritte. Dies umfasst insbesondere die Autorisierung an
              Energielieferanten zu erteilen, alle Handlungen vorzunehmen und Erklärungen abzugeben, die für den Wechsel
              des Energielieferanten sowie der Belieferung der Anlage des Kunden mit Energie nötig sind; dies beinhaltet
              auch die Kündigung des bisherigen Energieliefervertrages und der Vereinbarung des Vorleistungsmodells mit
              dem Netzbetreiber;
            </li>
            <li>
              den Anbieter von Energielieferungen, Mobilfunk oder Internet zu ermächtigen Zahlungen vom Konto des Kunden
              mittels SEPA-Lastschrift einzuziehen, die für (durchgeführten) Energielieferungen, Mobilfunkverträge und
              Internet durch den Anbieter verrechnet werden. Weiters, um das Kreditinstitut des Kunden anzuweisen, die
              vom Anbieter auf dem Konto gezogenen SEPA-Lastschriften einzulösen;
            </li>
            <li>
              Verbrauchswerte des Kunden von dem jeweiligen Netzbetreiber und/oder Energielieferanten oder
              Mobilfunkbetreibers oder des Internetanbieters anzufordern;
            </li>
            <li>
              Einsichtnahme und Übernahme der beim Anbieter und Betreiber vorhandenen Wechsel- und Vertragsdaten wie
              Verbrauch, Laufzeit, Preise, Rechnungen etc durch Kontaktaufnahme, Online-Zugang, automatischem
              Datenaustausch, E-Mail Benachrichtigungen des Anbieters bzw. Betreibers;
            </li>
          </ul>
          <p>
            3.2. Dauer der Vollmacht
            <br />
            Die Bevollmächtigung ist mit der Laufzeit des Vertragsverhältnisses zwischen der centralize GmbH und dem
            Kunden befristet. Der Kunde kann die Vollmacht aber gegenüber centralize jederzeit schriftlich widerrufen.
          </p>
          <h3>
            4. ALLGEMEINER LEISTUNGSUMFANG
          </h3>
          <p>
            4.1. Gegenstand des Vertrages ist das speichern, verwalten und verarbeiten von Dokumenten und Verträgen des
            Kunden. Weiters der Vergleich von Tarifen auf dem österreichischen Strom-, Gas-, Mobilfunk- und
            Internetmarkt zum Zweck der Vornahme eines Energie-, Mobilfunk- oder Internetanbieterwechsels für den
            Kunden. Dies beinhaltet einen Vergleich der aktuellen Preise und Konditionen der Anbieter am
            österreichischen Strom-, Gas-, Mobilfunk- und Internetmarkt und die Abwicklung von kontinuierlichen
            Vertragswechseln für Strom- und Gaslieferverträge, sowie Mobilfunk- und Internetverträge des Kunden durch
            centralize auf Basis der vom Kunden gewährten Vollmacht, sofern der Kunde keiner (weiteren) Vertragsbindung
            unterliegt. Im Falle einer bald auslaufenden Vertragsbindung des Kunden mit seinem Energie-, Mobilfunk- oder
            Internetanbieter wird centralize zeitnah bereits Konditionen für den Kunden vergleichen und zeitnah nach
            Ende der Bindung den Anbieterwechsel vornehmen.
            <br />
            4.2. Die Lieferung von Strom und/oder Gas sowie der Transport der gelieferten Energiemenge sind nicht
            geschuldet.
          </p>
          <h3>
            5. BESONDERER LEISTUNGSUMFANG
          </h3>
          <p>
            5.1. Tarifvergleich
            <br />
            5.1.1. Grundlage für den Vergleich von Preisen und Konditionen der Energie-, Mobilfunk und Internetanbieter
            ist der Tarifrechner von Compera GmbH. Weitere Kriterien sind vom Vergleich ausdrücklich nicht umfasst. Ist
            der Tarifrechner aus Gründen, die nicht in unserer Sphäre liegen oder von uns nicht verschuldet worden,
            nicht verfügbar, so holen wir den Tarifvergleich nach Wiederverfügbarkeit des Tarifrechners nach. centralize
            behält sich vor, nur zu Tarifen, die online verfügbar und abschließbar sind, zu wechseln.
            <br />
            5.1.2. Der Kunde kann allfällige Präferenzen (zB Produktanforderungen wie Ökostrom, Strom aus Österreich,
            elektronische oder postalische Rechnung, Gesamtrechnung etc) gegenüber centralize ausschließlich per E-Mail
            an <a href="mailto:office@centralize.at">office@centralize.at</a> hinterlegen lassen. Diese Anforderungen
            wirken sich auf die Tarifauswahl aus. Andernfalls liegt die Auswahl der Anbieter im Ermessen von centralize
            rein auf Basis des durchgeführten Tarifvergleichs.
            <br />
            5.1.3. Der Jahresverbrauch des Kunden dient neben der Postleitzahl und dem aktuellen Lieferanten als
            Eingabewert bei der Ermittlung des günstigsten Tarifs im compera Tarifkalkulator. Der Jahresenergieverbrauch
            wird entweder vom Kunden angegeben, aus der von ihm übermittelten Rechnung / Verträgen / Dokumenten durch
            centralize ausgelesen oder beim bestehenden Energieanbieter beantragt. Der Verbrauch von Mobilfunkeinheiten
            bzw. Internetdaten kann nur aus bereitgestellten Rechnungen, auslesen von Onlinezugängen bzw. durch
            beantragen bei den Mobilfunk- und Internetanbietern ausgelesen werden. Entspricht der angegebene bzw. der in
            der Rechnung dargestellte Verbrauch nicht einem Verbrauchszeitraum von 365 Tagen +-15 Tagen, wird der
            Verbrauch auf 365 Tage hoch- bzw. runtergerechnet. Dabei wird der Verbrauch durch die Anzahl der
            Verbrauchstage dividiert und anschließend mit 365 multipliziert.
            <br />
            5.1.4. Verfügt der Kunde über keine Jahresrechnung da er noch keine vom aktuellen Energieanbieter erhalten
            hat, oder steht der Kunde vor einem Umzug und hat von der neuen Adresse noch keine Jahresrechnung, so kann
            er dennoch seine Anmeldung über den „Ich habe noch keine“-Anmeldungspfad oder per E-Mail an
            <a href="mailto:office@centralize.at">office@centralize.at</a> bei centralize fortsetzen. Der Kunde muss
            einen Verbrauch in kWh angeben. Diesen kann er frei angeben oder mithilfe der Personenanzahl im Haushalt
            oder der Wohnfläche schätzen (Datenquelle E-Control). Der Kunde muss Informationen angeben, welche der
            jeweilige neue Energielieferant benötigt um den Kunden zu identifizieren bzw. bei dem Netzbetreiber zu
            melden, wie die Zählpunktbezeichnung oder die Zählernummer und den Netzbetreiber. Aufgrund unterschiedlicher
            Anforderungen der einzelnen Energieanbieter betreffend der geforderten Zählpunkt-, Adress- und
            Personenangaben, kann centralize nicht gewährleisten, dass der Energieanbieter bei einem Mangel an den
            geforderten Informationen den Kunden annimmt bzw. nicht mit dem Kunden in Kontakt tritt um die restlichen
            Informationen vom Kunden zu erhalten.
            <br />
            5.1.5. centralize wird für den Kunden eine maximale Bindungsfrist von 24 Monaten eingehen.
            <br />
            5.1.6. Die im Zuge der Registrierung angegebenen Daten des Kunden werden für den Tarifvergleich herangezogen
            und mit den anderen im Tarifrechner verfügbaren Energie-, Mobilfunk- und Internettarifen verglichen. Hierbei
            wird eine mögliche Kosteneinsparung durch den Tarifrechner berechnet und ein Vertragswechsel automatisch
            durchgeführt, wenn dies (auch unter Berücksichtigung der an uns zu leistenden Servicegebühr) wirtschaftlich
            sinnvoll ist (vgl Pkt 3. der Vollmacht sowie Pkt 5.3).
            <br />
            5.1.7. Im Rahmen der Änderung von Pkt 1.4 können wir jederzeit das Serviceangebot auf der Online-Plattform
            verändern, erweitern oder einstellen und behalten uns daher das Recht vor, weitere Informationen und
            Unterlagen vom Kunden für einen sinnvollen Tarifvergleich zu verlangen.
            <br />
            5.1.8. Wichtige Hinweise zum compera Tarifrechner:
            <br />
            Der Preisrechner ist nicht geeignet, die Stromrechnungen aus der Vergangenheit zu überprüfen. Die
            tatsächlichen Gesamtkosten können von den errechneten Werten abweichen. Gründe hierfür sind beispielsweise:
          </p>
          <ul>
            <li>
              Preisänderungen einzelner Lieferanten;
            </li>
            <li>
              Änderung von Systemnutzungsentgelten (SNE-VO);
            </li>
            <li>
              Hinzukommen oder Wegfallen von Steuern und Abgaben;
            </li>
            <li>
              Veränderter Jahresverbrauch.
            </li>
          </ul>
          <p>
            Im Tarifkalkulator werden die Jahresgesamtkosten aller Produkte dargestellt. Die Jahresgesamtkosten werden
            auf Basis des jeweils zum Zeitpunkt der Abfrage gültigen Energiepreises hochgerechnet, dabei werden die
            Monatskosten mit zwölf multipliziert. Dies gilt auch für Preismodelle mit automatischer Preisanpassung. Für
            diese Produkte erstellt compera keine Prognose für die Entwicklung des jeweiligen Indexes oder des
            Energiepreises. Solche möglichen Veränderungen werden von uns daher nicht mitberücksichtigt.
          </p>
          <p>
            Findet sich der Anbieter des Kunden oder sein Produkt nicht in der Auflistung, so hat der Energie-,
            Mobilfunk- oder Internetanbieter keine Daten zur Verfügung gestellt oder ist das jeweilige Produkt
            möglichweise nicht mehr im Angebot des jeweiligen Energie-, Mobilfunk- oder Internetanbieter . Dann werden
            diese Daten von uns auch nicht berücksichtigt.
          </p>
          <p>
            5.2. Entscheidung für einen Anbieterwechsel
            <br />
            Grundlage über die Entscheidung für einen Anbieterwechsel ist die folgende Herangehensweise: Mittels der vom
            Kunden angegebenen oder in der von ihm übermittelten Energierechnung enthaltenen Angaben zum
            Energieverbrauch (in kWh), die Postleitzahl und aktuellem Energieanbieter des Kunden wird ausschließlich auf
            Basis der im compera Tarifkalkulator (und keiner sonstigen Entscheidungsgrundlage) hinterlegten
            tagesaktuellen Tarifangebote dieses aktuellen Anbieters (sofern vorhanden), ein Referenzjahrespreis
            errechnet. Ausgehend von dem selben Energieverbrauch und der vom Kunden gewählten Präferenzen („Ökostrom“,
            „Strom aus Österreich“, „Gesamtrechnung“, „Online-Rechnung“) werden die tagesaktuellen Tarifangebote aller
            im compera Tarifkalkulator hinterlegten Anbieter herangezogen und voraussichtliche Neujahrespreise
            ermittelt. Diese Neujahrespreise werden mit dem Referenzjahrespreis verglichen. Ergibt sich ein niedrigerer
            Neujahrespreis im Vergleich zum Referenzpreis so wird der Wechsel des Kunden zu dem neuen Anbieter mit dem
            günstigsten Neujahrespreis eingeleitet, vorausgesetzt, der neue Energieanbieter nimmt den Kunden an.
            <br />
            Dies kann die centralize GmbH auch mehrmals pro Jahr (unterjährig, bis zu drei Mal), je nach aktueller
            Vertragslaufzeit des Kunden und der angebotenen Tarife laut compera Tarifkalkulator, vollziehen, sofern dem
            Kunde, unter Voraussetzung der obigen Herangehensweise, wieder ein Wechsel zu einem günstigeren Anbieter
            angeboten werden kann.
          </p>
          <p>
            5.3. Ablauf des Energieanbieterwechsel
          </p>
          <p>
            5.3.1. Nach erfolgtem Tarifvergleich wertet centralize den wirtschaftlichen Nutzen eines
            Energieanbieterwechsels aus. Im Falle einer wirtschaftlich sinnvollen Ersparnis informiert centralize den
            Kunden formlos darüber, kündigt anschließend auf Basis der bei der Registrierung erteilten Bevollmächtigung
            den Vertrag mit dem aktuellen Energiediensteanbieters (bzw beauftragt den neuen Energiediensteanbieter mit
            der Kündigung des alten Energiediensteanbieters) und schließt einen neuen im Namen des Kunden ab.
            <br />
            5.3.2. Der Kunde wird nach initiiertem Wechsel des Energiediensteanbieters zeitnahe benachrichtigt. Um den
            automatischen Wechselservice gewährleisten zu können sowie jederzeit Einsicht in den Fortschritt des
            Wechselprozesses zu haben, gibt centralize im Zuge der Anmeldung beim Energiediensteanbieter für den Kunden,
            in dessen Auftrag und auf dessen Namen, alle nötigen Erklärungen ab (via Web-Interface, Email, oder
            vergleichbar). centralize behält eine Kopie aller Korrespondenz, Vertragsunterlagen und Rechnungen für
            Zwecke der weiteren Vertragserfüllung und leitet sämtliche Information nach dem Wechsel und
            Vertragsabschluss mit dem Energiediensteanbieter an den Kunden weiter. Weiters erhält der Kunde die
            Vertragsunterlagen inklusive der anwendbaren AGBs des Energiediensteanbieters.
            <br />
            5.3.3. Sobald der Vertrag mit dem neuen Energiediensteanbieter keiner Kündigungsfrist mehr unterliegt, wird
            erneut ein regelmäßiger Tarifvergleich mit anderen Anbietern durch uns vorgenommen.
          </p>
          <h3>
            6. LAUFZEIT, LEISTUNGSFRIST UND KÜNDIGUNG
          </h3>
          <p>
            6.1. Der Servicevertrag wird auf unbestimmte Zeit geschlossen.
            <br />
            6.2. centralize hat unmittelbar nach Vertragsabschluss vorerst mit der Durchsicht und Prüfung aller
            erhaltenen Unterlagen zu beginnen, um einen kundenspezifischen Tarifvergleich durchführen zu können.
            <br />
            6.3. Der Vertrag kann nach Ablauf der Mindestlaufzeit unter Einhaltung einer Kündigungsfrist von einem Monat
            zu jedem Monatsende ordentlich gekündigt werden.
            <br />
            6.4. Unabhängig davon hat der Kunde das Recht den Vertrag jederzeit außerordentlich aus wichtigem Grund zu
            kündigen.
            <br />
            6.5. Kündigungen sind schriftlich an die centralize GmbH, Untere Landstraße 37, 3500 Krems an der Donau oder
            per E-Mail an [kuendigung@centralize.at] zu übermitteln.
          </p>
          <h3>
            7. HAFTUNG
          </h3>
          <p>
            7.1. Wir haften für Schäden nach den gesetzlichen Bestimmungen. Die Haftung für leicht fahrlässig zugefügte
            Schäden ist jedoch ausgeschlossen. Diese Haftungsbeschränkung gilt nicht für Schäden aus der Verletzung des
            Lebens, des Körpers oder der Gesundheit von Menschen und für Ansprüche nach dem Produkthaftungsgesetz.
            <br />
            7.2. Kunden, die Unternehmer sind, haben das Vorliegen von Vorsatz oder grober Fahrlässigkeit zu beweisen
            und Schadenersatzansprüche innerhalb von einem Jahr geltend zu machen. Die in diesen AGB enthaltenen oder
            sonst vereinbarten Bestimmungen über Schadenersatz gelten diesfalls auch dann, wenn der
            Schadenersatzanspruch neben oder anstelle eines Gewährleistungsanspruches geltend gemacht wird.
            <br />
            7.3. Der Produkt- und Preisvergleich wird anhand des von compera zur Verfügung gestellten compera
            Tarifrechners und der darin veröffentlichten Tarife berechnet. Bei der laufenden Implementierung und
            Aktualisierung der Tarife sowie der Verarbeitung der vom Kunden angegebenen Daten ist centralize um
            größtmögliche Sorgfalt bemüht. Dennoch kann es im Einzelfall zu Übertragungsfehlern, technischen
            Unterbrechungen und Irrtümern kommen. centralize leistet daher keine Gewähr für den compera Tarifrechner,
            und insbesondere dass sämtliche am österreichischen Markt befindliche Energiediensteanbieter oder Produkte
            in das Vergleichsergebnis einbezogen werden. centralize übernimmt daher – außer bei vorsätzlichem oder grob
            fahrlässigem Verhalten von centralize – keine Haftung für eine vollständige und richtige Produkt- und
            Preisübersicht oder für die vollständige und richtige Übermittlung der vom Kunden angegebenen Daten an die
            Energiediensteanbieter.
            <br />
            7.4. centralize übernimmt weiters keinerlei Haftung für die Verfügbarkeit der Tarife. Verantwortlich dafür
            ist der jeweilige Energiediensteanbieter.
            <br />
            7.5. centralize garantiert keinen Anspruch des Kunden auf den tatsächlichen Abschluss eines entsprechenden
            Vertrages mit dem Energiediensteanbieter. centralize übernimmt insbesondere keine Haftung wenn der beim
            Tarifvergleich ermittelte Energiediensteanbieter den Kunden, aus welchen Gründen auch immer, nicht annimmt.
            In diesem Fall wird ein erneuter Tarifvergleich vollzogen und der nächstgereihte, günstigste
            Energiediensteanbieter gewählt. Insbesondere übernimmt centralize keine Haftung für die Ablehnung des Kunden
            durch den gewählten Energieanbieter aufgrund eines bevorstehenden oder im Gang befindlichen Umzugs des
            Kunden.
          </p>
          <h3>
            8. WIDERRUFSRECHT FÜR VERBRAUCHER
          </h3>
          <p>
            8.1. Widerruf des Servicevertrages mit centralize
            <br />
            8.1.1. Ist der Kunde Verbraucher im Sinne des KSchG, hat das Recht, diesen Vertrag innerhalb der
            Widerrufsfrist ohne Angabe von Gründen zu widerrufen.
            <br />
            8.1.2. Die Widerrufsfrist beträgt vierzehn Tage ab Vertragsabschluss.
            <br />
            8.1.3. Um das Widerrufsrecht auszuüben, muss der Kunde uns (centralize GmbH, Untere Landstraße 37, 3500
            Krems an der Donau, ruecktritt@centralize.at) mit einer eindeutigen Erklärung (zB ein mit der Post
            versandter Brief oder E-Mail) über seinen Entschluss, diesen Vertrag zu widerrufen, informieren. Der Kunde
            kann dafür das unten zur Verfügung gestellte Muster-Widerrufsformular verwenden, das jedoch nicht
            vorgeschrieben ist.
            <br />
            8.1.4. Zur Wahrung der Widerrufsfrist reicht es aus, dass der Kunde die Mitteilung über Ausübung des
            Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.
            <br />
          </p>
          <p>
            Muster-Widerrufsformular
            <br />
            Der Kunde kann das folgende Formular verwenden und an uns senden, wenn er den Vertrag widerrufen will:
            <br />
            An centralize GmbH, Untere Landstraße 37, 3500 Krems an der Donau, ruecktritt@centralize.at
            <br />
            Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den folgende
            Dienstleistungen (*):
            <br />
            Bestellt am (*):
            <br />
            Name des/der Verbraucher(s):
            <br />
            Anschrift des/der Verbraucher(s):
            <br />
            Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
            <br />
            Datum
            <br />
            (*) Unzutreffendes bitte streichen.
          </p>
          <p>
            8.2. Widerruf des Wechsel bzw. des Liefervertrages mit dem neuen Energieanbieter
            <br />
            Konsumenten haben 14 Tage nach Vertragsabschluss mit dem neuen Energiediensteanbieter Zeit, diesen Vertrag
            zu widerrufen. Dazu hat der Kunde den in den Vertragsunterlagen des neuen Anbieters beschriebenen
            Widerrufsprozess zu befolgen und centralize über die Ausübung des Widerrufsrechts per E-Mail an
            <a href="mailto:wechsel-widerruf@centralize.at">
              wechsel-widerruf@centralize.at
            </a>
            zu informieren. Darüber hinaus wird der Kunde dazu angehalten, den alten Energiediensteanbieter sowie den
            Netzbetreiber über den Widerruf des Anbieterwechsels in Kenntnis zu setzen um die ununterbrochene
            Energieversorgung nicht zu gefährden. Nur wenn der Kunde centralize umgehend über den Widerruf informiert,
            kann centralize einen neuen Anbieter für den Kunden suchen um schnellstmöglich einen neuen
            Energieliefervertrag für den Kunden abzuschließen. Festgehalten wird, sollte der Kunde die Information über
            den Widerruf des Anbieterwechsels nicht umgehend an centralize weiterleiten, besteht die Gefahr, dass der
            Kunde keinen aufrechten Energieliefervertrag hat. Der Kunde hat sich in einem solchen Fall eigenständig um
            Ersatz zu kümmern.
          </p>
          <h3>
            9. DATENSCHUTZ
          </h3>
          <p>
            9.1. Die uns vom Kunden im Rahmen der Geschäftsbeziehung bekannt gegebenen persönlichen Daten wie zB Name,
            Adresse, E-Mail, Telefonnummer werden von uns zu Zwecken der Vertragserfüllung gespeichert und verarbeitet,
            dies insbesondere für den Vergleichs von Produkten der Anbieter, der Kontaktaufnahme mit dem Kunden, dem
            Energieanbieterwechsel, der Kündigung von Energielieferverträgen, dem Eingehen neuer Energielieferverträge,
            oder der Erstellung eines Kundenkontos verwendet. Wir verarbeiten die Daten im Rahmen des derzeit geltenden
            Datenschutzgesetzes sowie zukünftig nach deren Inkrafttreten nach dem Datenschutzanpassungsgesetz 2018 und
            der Datenschutzgrundverordnung rein zu Zwecken der Vertragserfüllung.
            <br />
            9.2. Durch Anklicken der Checkbox stimmt der Kunde ausdrücklich der Verwendung seiner bekanntgegebenen,
            personenbezogenen Daten durch centralize und/oder der folgenden hierfür von centralize möglicherweise
            beauftragten Partner: [wie etwa Marktforschungsunternehmen], zum Zweck der Qualitätssicherung, Information
            über neue oder ähnliche Produkte sowie Spar-Tipps, oder Kündigung eines Produkts, via Telefon und/oder Email
            zu. Diese Zustimmung kann vom Kunden jederzeit und kostenlos widerrufen werden.
            <br />
            9.3. Der Kunde ist verpflichtet uns Änderungen seiner Wohnanschrift, Kontaktdaten sowie den Wechsel des
            Strom-/Gaslieferanten während aufrechtem Vertragsverhältnis bekanntzugeben. Unterlässt er diese Mitteilung
            neuer Kontaktdaten, so gelten Erklärungen auch dann als zugegangen, falls sie an die von ihm zuletzt bekannt
            gegebene Adresse gesendet werden.
          </p>
          <p>
            10.4. Zusätzlich zu diesen AGB gilt die Datenschutzerklärung, abrufbar unter
            <router-link to="/dsgvo" v-slot="{ href }" custom>
              <a @click.stop :href="href" target="_blank">centralize.at/datenschutz</a>
            </router-link>
            als integraler Bestandteil dieser AGB.
          </p>
          <h3>
            10. SCHLUSSBESTIMMUNGEN
          </h3>
          <p>
            10.1. Bei Unternehmergeschäften sowie bei Verbrauchergeschäften, bei denen der Verbraucher zum Zeitpunkt der
            Klageerhebung weder Wohnsitz oder gewöhnlichen Aufenthalt in Österreich hat noch im Inland beschäftigt ist,
            ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag das für 3500 örtlich und
            sachlich zuständige Gericht. Ist der Kunde Unternehmer, gilt die ausschließliche Gerichtsstandswahl
            jedenfalls. Unsere Befugnis, in diesem Fall auch ein anderes für den Kunden zuständiges Gericht anzurufen
            bleibt unberührt.
            <br />
            10.2. Es gilt ausschließlich das materielle Recht der Republik Österreich unter Ausschluss der
            Verweisungsnormen und des UN Kaufrechts. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als zwingende
            Bestimmungen des Rechts des Staates, in der er seinen gewöhnlichen Aufenthalt hat, nicht verdrängt werden.
            <br />
            10.3. Mit Ausnahme der Regelung nach Pkt 1.4 (sowie Verweise darauf) bedürfen Änderungen und Ergänzungen des
            Vertrages der Schriftform. Das gilt auch für die Aufhebung dieses Formerfordernisses. Die Schriftform ist
            bei Erklärungen in Form von E-Mail oder anderen technischen Hilfsmitteln gewahrt.
            <br />
            10.4. Die Vertrags- und Geschäftssprache ist Deutsch.
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
