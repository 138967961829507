


































































import Vue from "vue";
import Component from "vue-class-component";
import { Address, Actions } from "../store/modules/address.types";
import { mapState, ActionMethod } from "vuex";
import { addressesNamespace } from "../store";

const AddressListBase = Vue.extend({
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
});

@Component
export default class AddressList extends AddressListBase {
  @addressesNamespace.Action(Actions.load)
  load!: ActionMethod;
  @addressesNamespace.Action(Actions.loadAddressByUser)
  loadAddressByUser!: ActionMethod;
  @addressesNamespace.Action(Actions.createAddress)
  createAddress!: ActionMethod;
  @addressesNamespace.Action(Actions.createAddressForUser)
  createAddressForUser!: ActionMethod;
  @addressesNamespace.Action(Actions.updateAddress)
  updateAddress!: ActionMethod;
  @addressesNamespace.Action(Actions.delete)
  deleteAddress!: ActionMethod;

  addresses: Address[] = [];

  async loadData() {
    if (this.userId != null) {
      this.addresses = (await this.loadAddressByUser(this.userId)) as Address[];
    } else {
      this.addresses = (await this.load()) as Address[];
    }
  }

  async updateAddresses(address: Address) {
    await this.updateAddress(address);
    const index = this.addresses.findIndex((add) => add.ID == address.ID);
    if (index !== -1) {
      Vue.set(this.addresses, index, address);
      this.addresses[index] = address;
    }
  }

  async addAddress(address: Address) {
    console.log("adding address?!?");
    if (this.userId != null) {
      const newAddress = await this.createAddressForUser({
        address,
        user: this.userId,
      });
    } else {
      const newAddress = await this.createAddress(address);
    }
  }
}
