<template>
  <v-footer id="dashboard-footer" inset app absolute width="auto">
    <v-container fluid>
      <v-row align="center" no-gutters>
        <v-col v-for="(link, i) in links" :key="i" class="text-center mb-sm-0 mb-5" cols="auto">
          <router-link :to="link.to" class="mr-0 grey--text text--darken-3" v-text="link.text" />
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col cols="auto">
          <div class="body-1 font-weight-light pt-6 pt-lg-0 text-center">
            &copy; {{ new Date().getFullYear() }}, made with
            <v-icon size="18">
              mdi-heart
            </v-icon>
            by <a href="https://www.centralize.at">Centralize</a> in Austria.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  computed: {
    links() {
      return [
        {
          to: "/dsgvo",
          text: this.$t("dsg"),
        },
        {
          to: "/agb",
          text: this.$t("agb"),
        },
        {
          to: "/imprint",
          text: this.$t("imprint"),
        },
      ];
    },
  },
};
</script>

<style lang="sass">
#dashboard-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
