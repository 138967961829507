import AWS from "aws-sdk";
import Vue from "vue";
import Vuex, { createNamespacedHelpers } from "vuex";
import { cognitoModule } from "./modules/cognito";
import { contractModule } from "./modules/contract";
import { providerModule } from "./modules/provider";
import { addressModule } from "./modules/address";
import { namespace, Getter } from "vuex-class";
import { set } from "./helpers";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

export enum Actions {
  updateCredentials = "updateCredentials",
  setDrawer = "setDrawer",
}

export enum Mutations {
  setSnackbar = "setSnackbar",
  setSearch = "setSearch",
}

export const contracts = createNamespacedHelpers("contract");
export const providers = createNamespacedHelpers("provider");
export const addresss = createNamespacedHelpers("address");
export const cognito = createNamespacedHelpers("cognito");

export const contractsNamespace = namespace("contract");
export const providersNamesapce = namespace("provider");
export const addressesNamespace = namespace("address");
export const cognitoNamespace = namespace("cognito");

const vuexPersist = new VuexPersistence({
  modules: ["address", "contract", "provider"],
});

const store = new Vuex.Store({
  state: { email: "", isLoading: false, isReady: false, password: "", snackbar: {}, drawer: true, search: "" },
  plugins: [vuexPersist.plugin],
  mutations: {
    setEmail: set("email"),
    setIsReady: set("isReady"),
    setIsLoading: set("isLoading"),
    setPassword: set("password"),
    setUser: set("user"),
    setSnackbar: set("snackbar"),
    setDrawer: set("drawer"),
    setSearch: set("search"),
  },
  actions: {
    [Actions.setDrawer]({ commit }, payload) {
      commit(Actions.setDrawer, payload);
    },
    async [Actions.updateCredentials]({ rootGetters }): Promise<AWS.CognitoIdentityCredentials> {
      const session = rootGetters["cognito/session"];
      const iss = session.idToken.payload.iss.replace("https://", "");
      const token = session.idToken.jwtToken;
      const credentials: AWS.CognitoIdentityCredentials.CognitoIdentityCredentialsInputs = {
        IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID as string,
        Logins: {
          [iss]: token,
        },
      };
      AWS.config.update({
        region: process.env.VUE_APP_COGNITO_USER_POOL_REGION as string,
        credentials: new AWS.CognitoIdentityCredentials(credentials),
      });
      const awsCredentials = AWS.config.credentials as AWS.CognitoIdentityCredentials;
      await awsCredentials.getPromise();
      if (awsCredentials.needsRefresh()) {
        await awsCredentials.refreshPromise();
      }
      return awsCredentials;
    },
  },

  modules: {
    contract: contractModule,
    provider: providerModule,
    address: addressModule,
    cognito: cognitoModule,
  },
});

export default store;
