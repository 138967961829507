<template>
  <v-form>
    <snackbar></snackbar>
    <validation-observer v-slot="{ invalid }">
      <v-container>
        <v-row id="login" justify="center">
          <v-card class="elevation-4">
            <v-card-title style="word-break: normal;">
              <v-row align="center" justify="center" class="pt-10">
                <base-heading class="text-center grey--text text--darken-2 " :text="$t('registerHeadline')" />
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="auto" md="6">
                  <v-list>
                    <v-list-item three-line>
                      <v-list-item-icon>
                        <v-icon large color="pink">mdi-currency-eur</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <subheading>{{ $t("saveMoney") }}</subheading>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="text-h4">{{ $t("saveMoneyText") }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item three-line>
                      <v-list-item-icon>
                        <v-icon large color="purple">mdi-information-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <subheading>{{ $t("overview") }}</subheading>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="text-h4">{{ $t("overviewText") }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item three-line>
                      <v-list-item-icon>
                        <v-icon large color="blue lighten-2">mdi-heart</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <subheading>{{ $t("organize") }}</subheading>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="text-h4">{{ $t("organizeText") }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="auto" md="6">
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <validation-provider :name="$t('firstname')" rules="required" v-slot="{ errors }">
                          <v-text-field
                            :label="$t('firstname') + '*'"
                            v-model="firstName"
                            :error-messages="errors"
                            autocomplete="first-name"
                            prepend-icon="mdi-account"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="6">
                        <validation-provider :name="$t('lastname')" rules="required" v-slot="{ errors }">
                          <v-text-field
                            :label="$t('lastname') + '*'"
                            v-model="lastName"
                            :error-messages="errors"
                            autocomplete="family-name"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" md="6">
                        <validation-provider
                          :name="$t('email')"
                          rules="required|email"
                          v-slot="{ errors }"
                          vid="originalEmail"
                        >
                          <v-text-field
                            :label="$t('email') + '*'"
                            v-model.trim="email"
                            :error-messages="errors"
                            autocomplete="username"
                            prepend-icon="mdi-email"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <validation-provider
                          :name="$t('emailConfirmation')"
                          rules="required|email|confirmed:@originalEmail"
                          v-slot="{ errors }"
                          vid="emailConfirm"
                        >
                          <v-text-field
                            :label="$t('emailConfirmation') + '*'"
                            v-model="emailConfirmation"
                            :error-messages="errors"
                            autocomplete="username"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" md="6">
                        <validation-provider
                          :name="$t('password')"
                          rules="required|verify_password|min:8"
                          vid="originalPassword"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model.trim="password"
                            :error-messages="errors"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            class="mb-3"
                            :label="$t('password') + '*'"
                            name="password"
                            @click:append="show = !show"
                            autocomplete="new-password"
                            prepend-icon="mdi-lock"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="6">
                        <validation-provider
                          :name="$t('passwordConfirmation')"
                          rules="required|confirmed:@originalPassword"
                          v-slot="{ errors }"
                          vid="passwordConfirm"
                        >
                          <v-text-field
                            v-model="passwordConfirmation"
                            :error-messages="errors"
                            :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showConfirmation ? 'text' : 'password'"
                            class="mb-3"
                            :label="$t('passwordConfirmation') + '*'"
                            name="password"
                            @click:append="showConfirmation = !showConfirmation"
                            autocomplete="new-password"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col>
                        <v-checkbox outlined v-model="agb">
                          <template #label>
                            <span>
                              {{ $t("accept") }}
                              <router-link to="/agb" v-slot="{ href }">
                                <a @click.stop :href="href" target="_blank">{{ $t("agbAccept") }}</a> *
                              </router-link>
                            </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-checkbox outlined v-model="dsg">
                          <template #label>
                            <span>
                              {{ $t("accept") }}
                              <router-link to="/dsgvo" v-slot="{ href }">
                                <a @click.stop :href="href" target="_blank">{{ $t("dsgAccept") }}</a> *
                              </router-link>
                            </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-btn :disabled="invalid || !agb || !dsg" color="primary" @click="submit" rounded>
                        {{ $t("next") }}
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </validation-observer>
  </v-form>
</template>

<script>
import Subheading from "@/components/base/Subheading.vue";
import { mapActions, mapMutations } from "vuex";
export default {
  components: {
    Snackbar: () => import("@/components/Snackbar"),
    Subheading,
  },
  data: () => ({
    firstName: null,
    lastName: null,
    iEmail: null,
    iEmailConfirmation: null,
    password: null,
    passwordConfirmation: null,
    agb: null,
    dsg: null,
    show: null,
    showConfirmation: null,
  }),

  methods: {
    ...mapActions("cognito", ["registerUser"]),
    ...mapMutations(["setEmail", "setPassword", "setIsLoading", "setSnackbar"]),
    async submit() {
      this.hasError = false;
      this.setIsLoading(true);
      try {
        await this.registerUser({
          username: this.email,
          password: this.password,
          attributes: {
            given_name: this.firstName,
            family_name: this.lastName,
            email: this.email,
          },
        });
        this.setSnackbar({
          type: "success",
          msg: this.$t("registerSuccess"),
        });
        setTimeout(() => {
          this.$router.push("/");
        }, 2000);
      } catch (err) {
        this.setSnackbar({
          type: "error",
          msg: this.$t(err.code),
        });
      } finally {
        this.setIsLoading(false);
      }
    },
  },

  computed: {
    email: {
      set(val) {
        this.iEmail = val.toLowerCase();
      },
      get() {
        return this.iEmail;
      },
    },
    emailConfirmation: {
      set(val) {
        this.iEmailConfirmation = val.toLowerCase();
      },
      get() {
        return this.iEmailConfirmation;
      },
    },
  },
};
</script>

<style></style>
