













import Vue from "vue";
import Component from "vue-class-component";
import AppBar from "./AppBar.vue";
import Drawer from "./Drawer.vue";
import CoreFooter from "./Footer.vue";
@Component({
  components: {
    AppBar,
    Drawer,
    CoreFooter,
  },
})
export default class LoggedInLayout extends Vue {
  expandOnHover: boolean = false;
}
