




import Vue from "vue";

import Component from "vue-class-component";
import Layout from "@/components/core/Layout";

@Component({
  components: {
    Layout,
  },
})
export default class App extends Vue {
  name = "App";
}
