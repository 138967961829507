









































import Vue from "vue";
import Component from "vue-class-component";
@Component({})
export default class FAQ extends Vue {
  onboarding = 0;
  images = [
    "https://dummyimage.com/1920x1080/ffffff/000000.png&text=Slide 1",
    "https://dummyimage.com/1920x1080/ffffff/000000.png&text=Slide 2",
    "https://dummyimage.com/1920x1080/ffffff/000000.png&text=Slide 3",
    "https://dummyimage.com/1920x1080/ffffff/000000.png&text=Slide 4",
    "https://dummyimage.com/1920x1080/ffffff/000000.png&text=Slide 5",
  ];

  next() {
    this.onboarding = this.onboarding + 1 === this.images.length ? 0 : this.onboarding + 1;
  }
  prev() {
    this.onboarding = this.onboarding - 1 < 0 ? this.images.length - 1 : this.onboarding - 1;
  }
}
