export default {
  ok: "OK",
  cancel: "Abbrechen",
  submit: "Absenden",
  title: "Titel",
  contractTitle: "Neuen Vertrag hochladen!",
  contractSubtitle:
    "Hier kannst du deine Verträge hochladen. Klick einfach auf das Doumente Symbol und wähle die Vertragsart aus. Wir analysieren sie danach für dich und kommen mit Verbesserungsvorschlägen auf dich zu. Das Ganze ist für dich völlig kostenlos!",
  upload: "Hochladen",
  confirm: "Bestätigen",
  save: "Speichern",
  street: "Straße",
  streetNumber: "Hausnummer",
  floor: "Stock",
  stairs: "Stiege",
  doorNumber: "Tür",
  zip: "PLZ",
  city: "Stadt",
  country: "Land",
  accept: "Bitte akzeptiere unsere",
  salutation: "Anrede",
  birthday: "Geburtstag",
  personsInHousehold: "Personen im Haushalt",
  areaForHousehold: "Wohnfläche",
  trackingNumber: "Autftragsnummer",
  agbAccept: "AGBs",
  dsgAccept: "Datenschutz",
  password: "Passwort",
  toSignin: "Zum Login",
  passwordConfirmation: "Passwort bestätigen",
  email: "Email",
  emailConfirmation: "Email bestätigen",
  firstname: "Vorname",
  lastname: "Nachname",
  price: "Kosten",
  duration: "Vertragslaufzeit",
  classical: "oder klassisch!",
  contractNumber: "Vertragsnummer",
  saveMoney: "Geld sparen",
  saveMoneyText:
    "Wir haben centralize entwickelt, um dir das Leben zu erleichtern. Mit uns kannst du bis zu 60% der Kosten deiner Verträge sparen - völlig kostenlos",
  overview: "Übersicht behalten",
  overviewText: "Wir helfen dir, die Übersicht über deine Verträge zu behalten - damit du nie wieder zu viel bezahlst!",
  organize: "Weil wir dir das Leben erleichtern wollen!",
  organizeText: "Mit wenigen Klicks zu weniger Fixkosten - easy oder?",
  createAccount: "Registrieren",
  resetPassword: "Passwort zurücksetzen",
  resetPasswordSub: "Wir senden dir in Kürze einen Link zum Zurücksetzen deines Passworts",
  backToLogin: "Zurück zum Login",
  next: "Weiter",
  null: "",
  undefined: "",
  register: "Registrieren",
  signin: "Login",
  imprint: "Impressum",
  agb: "AGB",
  sepa: "SEPA-Vollmacht",
  cancellation: "Widerrufsbelehrung",
  dsg: "Datenschutz",
  dashboard: "Dashboard",
  home: "Dashboard",
  contracts: "Verträge",
  providers: "Anbieter",
  archive: "Archiv",
  "create-contract": "Vertrag hochladen",
  "how-does-this-work": "Wie funktioniert's?",
  contact: "Kontakt",
  logout: "Ausloggen",
  search: "Suchen",
  profile: "Benutzerprofil",
  "contract-detail": "Vertragsdetails",
  "contract-detail-tab": "Vertragsdetails",
  selectContractHeadline: "Bitte wähle einen Vertrag aus!",
  selectContract: "Vertrag auswählen",
  selectChangeOption: "Auswählen",
  contract: "Vertrag",
  details: "Details",
  status: "Status",
  changeOptions: "Wechseloptionen",

  registerHeadline: "Registriere dich jetzt!",
  headline: "Deine Verträge",
  archivedHeadline: "Archivierte Verträge",
  multiple: "Verträge",
  uploadMore: "Lade mehr Verträge hoch!",
  totalCost: "Gesamtkosten",
  totalCostFromAll: "aus all deinen Verträgen",
  savings: "Ersparnis",
  potentialSavings: "Potentielle Ersparnis",
  thisYear: "Beim nächsten Vertragswechsel",
  contractState: "Status",
  provider: "Anbieter",
  editProvider: "Anbieter editieren",
  edit: "Bearbeiten",
  delete: "Löschen",
  createProvider: "Anbieter anlegen",
  contractType: "Vertragsart",
  contractFiles: "Vertragsdateien",
  POWER: "Strom",
  GAS: "Gas",
  totalPricePerYear: "Preis pro Jahr",
  startDate: "Startdatum",
  endDate: "End Datum",
  createdAt: "Hochgeladen am",
  updatedAt: "Geändert am",
  changeAutomatically: "Automatischer Wechsel",
  usagePerYear: "Nutzung (pro Jahr)",
  meterNumber: "Zählpunktnummer",
  UID: "User",
  resultErrorHeadline: "Entschuldigung! Es ist etwas schief gegangen",
  resultErrorText: "Bitte gib deine Email Adresse an, um eine erneute Bestätigung zu erhalten",
  resendConfirmation: "Bestätigung erneut senden",
  welcomeHeadline: "Herzlich Willkommen",
  emailConfirmationText:
    "Vielen Dank für deine Registrierung. Log dich am besten gleich ein und profitiere von centralize!",
  contractDetailTitle: "Vertragsdetails",
  previewHeadline: "Vertragsansicht",
  previewSubheadline:
    "Hier siehst du den von dir hochgeladenen Vertrag. Du kannst das Dokument jederzeit runterladen - wir bewahren es dennoch sicher für dich auf!",
  dataheadline: "Vertragsdetails",
  datasubheadline:
    "Hier siehst du die Details zu deinem hochgeladenen Vertrag. Die Daten werden durch centralize bearbeitet und gewartet. Auf Basis der Daten werden wir dir Wechseloptionen anbieten und den Vertrag für dich wechseln.",
  statusheadline: "Vertragsstatus",
  statussubheadline:
    "Hier siehst du den Statusverlauf deines Vertrages - damit du immer einen Überblick hast, was gerade damit passiert",
  changeoptionsheadline: "Wechseloptionen",
  changeoptionheadline: "Wechseloption",
  changeoptionssubheadline:
    "Hier siehst du deine Wechseloptionen für deinen Vertrag. Ist dieses Feld leer bedeutet es, dass du noch keinen Wechsel mit uns durchführen kannst. Wir informieren dich per E-Mail, wenn es soweit ist.",
  "contract-archive": "Archiv",
  "contract-create": "Vertrag hochladen",
  tariffName: "Tarif",
  shippingAddress: "Lieferadresse",
  billingAddress: "Rechnungsadresse",
  addChangeOption: "Wechseloption hinzufügen",
  submitChangeOptions: "Wechseloption freigeben",
  economicalDecorations: "Ökologisches Zertifikat",
  notes: "Anmerkung",
  priceModel: "Preismodell",
  pricePerUnit: "Preis (je kWh) in Cent",
  basePrice: "Grundpauschale in Euro",
  networkCostPerYear: "Netzkosten + gesetzliche Abgaben in Euro",
  firstYearBonus: "Neukundenbonus in Euro",
  paymentType: "Bezahlmethode",
  billingCycle: "Abrechnungsperiode",
  contractDuration: "Vertragsdauer",
  cancellationPeriod: "Kündigungsfrist",
  singleInvoice: "Einzelrechnung",
  setContractChanged: "Wechsel Erfolgt",
  setContractChangedFailed: "Wechsel fehlgeschlagen",
  setContractChangedInProgress: "Vertragswechsel gestartet",
  comperaLink: "Compera Link",
  months: "Monate",
  changeView: "ADMIN Wechselansicht",
  signinSuccess: "Erfolgreich eingeloggt",
  registerSuccess: "Registrierung erfolgreich. Wir senden dir in kürze eine Bestätigung an deine Emailadresse",
  archivedSuccess: "Vertrag wurde archiviert",
  archiveContract: "Archivieren",
  changeAutomatically_true: "Automatischer wechsel aktiviert",
  changeAutomatically_false: "Automatischer wechsel deaktiviert",
  savingSuccess: "Daten gespeichert",
  savingError: "Fehler beim Speichern",
  UPLOADED: "Vertrag wird bearbeitet - wird melden uns in Kürze bei dir!",
  CONTRACT_DATA_ADDED: "Vertragsdaten hinterlegt - Wir analyisieren laufend deinen Vertrag",
  CHANGE_OPTIONS_ADDED:
    "Wechseloption verfügbar - entscheide dich am besten gleich für eine Alternative, wir erledigen den Rest",
  CHANGE_OPTION_SELECTED: "Wechseloption ausgewählt - Wir melden uns bei dir, sobald es Neuigkeiten gibt",
  CONTRACT_CHANGE_IN_PROGRESS: "Vertragswechsel gestartet",
  CONTRACT_CHANGED: "Vertragswechsel war erfolgreich",
  CONTRACT_CHANGE_FAILED: "Vertragswechsel fehlgeschlagen - Details erhälst du per E-Mail",
  TRANSITIONED_CONTRACT: "Vertragswechsel war erfolgreich - Details erhälst du per Email",
  ARCHIVED: "Vertrag ist archiviert und wird nicht in deine aktuellen Gesamtkosten mit einbezogen",
  UsernameExistsException: "Es gibt bereits einen User mit dieser Email Adresse.",
  UserNotConfirmedException:
    "Du hast eine E-Mail von uns erhalten. Bitte prüfe dein Postfach und bestätige deine Anmeldung. Wir freuen uns auf dich!",
  NotAuthorizedException: "Falsche Email oder Passwort",
  ExpiredCodeException: "Der Bestätigungscode ist nicht mehr gültig",
  CodeMismatchException: "Falscher Bestätigungscode",
  male: "Herr",
  female: "Frau",
  company: "Firma",
  defaultAddress: "Standard Adresse",
  defaultAddressSettings: "Das ändern der Standard Adresse wirkt sich auf alle zukünftigen Verträge aus",
  finish: "Abschließen",
  previous: "Zurück",
  addAddress: "Adresse anlegen",
  netPrice: "Netto",
  noContractsYetHeader: "Herzlich Willkommen bei centralize!",
  noContractsYetBody:
    "Es freut uns, dass du mit uns zusammen bares Geld uns kostbare Zeit sparen willst. Lade am besten gleich einen Strom- oder Gasvertrag hoch, wir erledigen den Rest für dich!",
  createContractSuccess:
    "Vielen Dank! Wir haben deine Dokumente  erhalten und melden uns in den nächsten 24h zurück! Bitte prüfe auch dein Email Posteingang und Spam Ordner",
  understandLabel: "Verstanden",
  attentionLabel: "Achtung!",
  changeAutomaticallyActivated:
    "Automatischer Vertragswechsel aktiviert. Centralize wird automatisch nach günstigeren Tarifen für dich Ausschau halten.",
  userProfileEncryption:
    "Deine Daten werden von Centralize nach strengen Maßgaben verschlüsselt und sind somit vor unberechtigtem Zugriff Dritter geschützt",
  addContractDocuments: "Dokument hinzufügen",
  contractChangeCheckbox: `Ich nehme die {agb}, die {cancellation}, die {dsg}, die {sepa} von {providerName}
                als auch die Erklärung zum {centralizeDsg} und die {centralizeAgb} von centralize zur Kenntnis. 
                Ich stimme der Übermittlung der Daten an {providerName} zur weiteren Bearbeitung zu.`,
  setChangeInProgress: "Wechsel initiieren",
  AT_NORM: "AT Norm",
  GLOBAL_2000_NORM: "Global 2000",
  INVOICE: "Rechnung",
  DIRECT_DEBIT: "Bankeinzug",
  STANDARD: "Standard",
  PRICE_GUARANTEE: "Preis Garantie",
  FLOATING: "variabler Preis",
  MONTHLY: "Monatlich",
  QUATERLY: "Vierteljährlich",
  BIANNUALY: "Halbjährlich",
  ANNUALLY: "Jährlich",
  myRequest: "Meine Anfrage",
  requestMessage:
    "Bitte lass uns wissen, wie wir dir helfen können und schick uns eine Nachricht und deine Fragen hier:",
  requestImprovement: "Du weißt wir sind neu, daher freuen wir uns auch über Verbesserungsvorschläge",
  requestSuccess: "Vielen Dank für deine Nachricht! Wir melden uns so schnell wie möglich bei dir.",
  toDashboard: "zurück zum Dashboard",
  yourPhoneNumber: "Deine Telefonnummer",
};
