<template>
  <v-app-bar absolute app flat height="75" color="transparent">
    <v-spacer />
    <v-spacer />
    <v-spacer />
    <v-btn text to="/auth/signup" color="white">
      <v-icon left>mdi-account-plus</v-icon>
      {{ $t("register") }}
    </v-btn>
    <v-btn text class="ml-4" to="/auth/signin" color="white">
      <v-icon left>mdi-fingerprint</v-icon>
      {{ $t("signin") }}
    </v-btn>
    <v-spacer />
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
};
</script>
