











import Vue from "vue";

import LoginForm from "@/components/LoginForm.vue";
import Snackbar from "@/components/Snackbar.vue";
import Component from "vue-class-component";

@Component({ components: { LoginForm, Snackbar } })
export default class Signin extends Vue {
  window = 0;

  created() {
    console.log("created");
  }
}
