




















































































































































































































































































































import Vue from "vue";
import { Computed, ActionMethod, MutationMethod } from "vuex";
import Component from "vue-class-component";
import AddressSelect from "@/components/AddressSelect.vue";
import {
  contracts,
  providers,
  providersNamesapce,
  addressesNamespace,
  contractsNamespace,
  cognitoNamespace,
} from "@/store";
import {
  Contract,
  Actions as ContractActions,
  Getters as ContractGetters,
  ContractState,
  ContractType,
  ContractPriceModel,
  PaymentType,
  BillingCyles,
  EconomicalDecoration,
  ContractChangeOption,
  Mutations,
} from "@/store/modules/contract.types";
import { Provider, Getters as ProviderGetters } from "@/store/modules/provider.types";
import { addressResource } from "../../api";
import { Address, Actions as AddressActions } from "../../store/modules/address.types";
import { Getters as CognitoGetters } from "@/store/modules/cognito/cognito.types";
import ContractChangeOptionDialog from "./ContractChangeOptionDialog.vue";
import ContractChangeOptions from "./ContractChangeOptions.vue";
import { ContractAware } from "../../mixins/ContractAware";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    AddressSelect,
    ContractChangeOptions,
    ContractChangeOptionDialog,
  },
})
export default class ContractData extends ContractAware {
  @providersNamesapce.Getter(ProviderGetters.providers) providers!: Provider[];
  @addressesNamespace.Action(AddressActions.createAddressForUser)
  createAddressForUser!: ActionMethod;
  @addressesNamespace.Action(AddressActions.updateAddress)
  updateAddress!: ActionMethod;
  @cognitoNamespace.Getter(CognitoGetters.isAdminOrCentralizeUser)
  isAdminOrCentralizeUser!: boolean;

  @Prop({ type: [String, Boolean], default: true }) readonly editable: string | boolean | undefined;

  public provider: Provider | null = null;
  private updating: boolean = false;
  public files: File[] = [];
  public startDateMenu: boolean = false;
  public endDateMenu: boolean = false;
  private dateMenu: any = null;
  private userAddresses: Address[] | null = [];
  private addressLoading = true;

  private gasMask = "AT900359##########################";
  private powerMask = "AT002000##########################";

  async mounted() {
    this.addressLoading = true;
    try {
      const addresses = (await addressResource.get(`/${this.contract!.UID}`)).data;
      this.userAddresses = addresses;
    } finally {
      this.addressLoading = false;
    }
  }

  async submit() {
    this.updating = true;
    console.log(this.contract.pricePerUnit);
    try {
      await this.updateContract({
        contract: this.contract,
        state:
          this.contract?.contractState === ContractState.UPLOADED ||
          this.contract?.contractState === ContractState.TRANSITIONED_CONTRACT
            ? ContractState.CONTRACT_DATA_ADDED
            : undefined,
      });
    } finally {
      this.updating = false;
    }
  }

  async submitChangeOption(changeOption: ContractChangeOption) {
    await this.updateChangeOptions({
      contract: this.contract,
      changeOption: changeOption,
    });
  }

  async hide() {
    await this.unlockContract(this.contract);
  }

  async createAddress(address: Address) {
    const created = await this.createAddressForUser({
      userId: this.contract!.UID,
      address,
    });
    this.userAddresses?.push(created);
  }

  get billingCycles() {
    return BillingCyles.map((cycle) => {
      return {
        ...cycle,
        key: this.$t(cycle.key),
      };
    });
  }

  get disabled() {
    return !this.isAdminOrCentralizeUser || !this.editable;
  }
}
