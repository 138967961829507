var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-loader-component',{on:{"ready":_vm.loadData}},[_c('v-container',{attrs:{"fluid":""}},[(!_vm.archive)?_c('v-row',{attrs:{"justify":"space-around","dense":""}},[_c('v-col',{attrs:{"cols":"9","md":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"orange","icon":"mdi-clipboard-text","title":_vm.$t('multiple'),"value":'' + _vm.contracts.length,"sub-icon":"mdi-alert","sub-icon-color":"error","sub-text":_vm.$t('uploadMore')}})],1),_c('v-col',{attrs:{"cols":"9","md":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"red","icon":"mdi-currency-eur","title":_vm.$t('totalCost'),"sub-icon":"mdi-tag","sub-text":_vm.$t('totalCostFromAll')}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.overallCost))+" ")])],1),_c('v-col',{attrs:{"cols":"9","md":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"green","icon":"mdi-thumb-up","title":_vm.$t('potentialSavings'),"sub-icon":"mdi-calendar","sub-text":_vm.$t('thisYear')}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.savings))+" ")])],1)],1):_vm._e(),_c('snackbar'),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-clipboard-text","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t(("" + (_vm.archive ? "archivedHeadline" : "headline"))))+" ")])]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contractList,"loading":_vm.loading,"custom-filter":_vm.customSearch,"items-per-page":_vm.isAdminOrCentralizeUser || _vm.archive ? 10 : 5,"search":_vm.search,"id":"contract-dashboard__datatable"},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.contractState",fn:function(ref){
var item = ref.item;
return [_c('v-col',{attrs:{"align":"start","cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.contractState === 'CONTRACT_CHANGED')?_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v(" mdi-check ")]):(item.contractState === 'CONTRACT_CHANGE_FAILED')?_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v(" mdi-close ")]):(item.contractState === 'CONTRACT_CHANGE_IN_PROGRESS')?_c('v-progress-circular',_vm._g({attrs:{"indeterminate":"","color":"primary"}},on)):_c('v-icon',_vm._g({attrs:{"left":"","color":"primary"}},on),[_vm._v("mdi-chat-alert")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(("" + (item.contractState)))))])])],1)]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(item.provider)?_c('v-col',{attrs:{"align":"end"}},[_c('v-img',{attrs:{"src":_vm.providerById(item.provider).icon,"aspect-ratio":16 / 9,"height":"25","contain":""}})],1):_vm._e()],1)]}},{key:"item.totalPricePerYear",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.totalPricePerYear)))]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [(item.startDate && item.endDate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-progress-linear',_vm._g({staticStyle:{"border-radius":"12px"},attrs:{"value":_vm.contractProgress(item),"height":"12","rounded":""}},on))]}}],null,true)},[_c('span',[_vm._v(" Start: "+_vm._s(_vm._f("moment")(item.startDate,"dd DD.MM.YYYY"))+" "),_c('br'),_vm._v(" End: "+_vm._s(_vm._f("moment")(item.endDate,"dd DD.MM.YYYY"))+" "),_c('br')])]):_vm._e()]}},{key:"item.changeAutomatically",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.changeAutomatically},on:{"change":function($event){return _vm.updateChangeAutomatically(item, $event)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"DD.MM.YYYY HH:mm"))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,"DD.MM.YYYY HH:mm"))+" ")]}},(_vm.isAdminOrCentralizeUser)?{key:"item.UID",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.userForId(item.UID).email))])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("User "+_vm._s(item.UID))]),_c('v-card-text',[_c('user-info',{attrs:{"UID":item.UID}})],1)],1)],1)]}}:null,(!_vm.archive)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canArchiveContract(item))?_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.archiveContract(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-archive")]),_vm._v(" "+_vm._s(_vm.$t("archiveContract"))+" ")],1):_vm._e()]}}:null],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"350","persistent":""},model:{value:(_vm.noContractsDialog),callback:function ($$v) {_vm.noContractsDialog=$$v},expression:"noContractsDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("noContractsYetHeader"))+" "),_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.noContractsDialog = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',{staticClass:"pb-6 pt-12 text-center"},[_vm._v(" "+_vm._s(_vm.$t("noContractsYetBody"))+" ")]),_c('v-card-actions',{staticClass:"pb-6  text-center"},[_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"font-weight-black",attrs:{"color":"primary","text":"","to":"/contracts/create"}},[_vm._v(" "+_vm._s(_vm.$t("create-contract"))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"font-weight-black",attrs:{"color":"success darken-1","text":"","to":"/faq"}},[_vm._v(" "+_vm._s(_vm.$t("how-does-this-work"))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }