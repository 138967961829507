import { DocumentDto } from "./documents.dto";

export interface ProviderState {
  data: Provider[];
}

export interface Provider {
  ID?: string;
  icon: string;
  name: string;
  website: string;
  documents: DocumentDto[];
}

export type ProviderDocumentKey = "agb" | "sepa" | "dsg" | "cancellation";

export interface UploadResult {
  agbs: AWS.S3.ManagedUpload.SendData | null;
  icon: AWS.S3.ManagedUpload.SendData | null;
  sepa: AWS.S3.ManagedUpload.SendData | null;
  dsg: AWS.S3.ManagedUpload.SendData | null;
  cancellation: AWS.S3.ManagedUpload.SendData | null;
}
export enum Actions {
  load = "load",
  createProvider = "createProvider",
  edit = "edit",
  delete = "delete",
  uploadProviderFiles = "uploadProviderFiles",
}

export enum Getters {
  providers = "providers",
  providerById = "providerById",
}
