









































import Vue from "vue";
import Component from "vue-class-component";
import { usersResource } from "../api";
@Component({})
export default class Contact extends Vue {
  submitted = false;
  submitting = false;

  requestMessage: string = "";
  requestImprovement: string = "";
  async submit() {
    this.submitting = true;
    try {
      await usersResource.post("/contact", {
        requestMessage: this.requestMessage,
        requestImprovement: this.requestImprovement,
      });
    } finally {
      this.submitting = false;
    }
    this.submitted = true;
  }
}
