<template>
  <v-container fluid>
    <snackbar />
    <validation-observer v-slot="{ invalid }">
      <v-row justify="center">
        <v-col cols="12" md="6" sm="9" xs="12">
          <base-material-card icon="mdi-account" color="primary lighten-2">
            <v-card-text>
              <validation-provider rules="required">
                <v-select :items="salutations" v-model="salutation" :label="$t('salutation') + '*'" />
              </validation-provider>
              <v-text-field v-model="title" :label="$t('title')" />

              <validation-provider rules="required">
                <v-text-field v-model="firstName" :label="$t('firstname') + '*'" />
              </validation-provider>

              <validation-provider rules="required">
                <v-text-field v-model="lastName" :label="$t('lastname') + '*'" />
              </validation-provider>

              <v-text-field v-model="contactMail" :label="$t('email')" readonly />
              <v-dialog ref="dialog" v-model="showBirthdhayPicker" persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="birthdate" :label="$t('birthday')" readonly v-on="on" />
                </template>
                <v-date-picker v-model="birthdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showBirthdhayPicker = false">{{ $t("cancel") }}</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save()">{{ $t("ok") }}</v-btn>
                </v-date-picker>
              </v-dialog>
              <vue-tel-input
                class="mb-4"
                mode="international"
                v-model="phoneNumber"
                defaultCountry="AT"
                :inputOptions="inputOptions"
                :onlyCountries="['AT', 'DE', 'CH']"
              />
              <v-text-field v-model="IBAN" label="IBAN" v-mask="ibanMask" :placeholder="ibanPlaceholder" />
              <v-text-field v-model="BIC" label="BIC" :placeholder="bicMask" dense />
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="12" md="6" sm="9" xs="12">
          <base-material-card icon="mdi-home" color="primary lighten-2">
            <v-card-text>
              <address-list />
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="personsInHousehold"
                    :label="$t('personsInHousehold')"
                    type="number"
                    thumb-label="always"
                    ticks="always"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="areaForHousehold"
                    :label="$t('areaForHousehold')"
                    type="number"
                    thumb-label="always"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>

      <v-row :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'">
        <v-col cols="12" md="6" sm="9" xs="12">
          <v-icon left>mdi-information</v-icon>{{ $t("userProfileEncryption") }}
        </v-col>
      </v-row>
      <v-row :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'start'">
        <v-col cols="12" md="3" sm="9" xs="12">
          <v-btn color="primary" :loading="loading" @click="save" :disabled="invalid" block class="mb-sm-1 mb-4">
            <v-icon left>mdi-check</v-icon>{{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import AddressList from "@/components/AddressList";
import Snackbar from "@/components/Snackbar";
import { VueTelInput } from "vue-tel-input";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { SalutationList, UserAttributes } from "@/store/modules/cognito/cognito.types";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
  pageTitle: "My Profile",
  components: {
    AddressList,
    VueTelInput,
    Snackbar,
  },
  data() {
    return {
      loading: false,
      showBirthdhayPicker: false,
      IBAN: "",
      ibanMask: "??## #### #### #### ####",
      ibanPlaceholder: "#### #### #### #### ####",
      bicMask: "",
    };
  },

  mounted() {
    this.IBAN = this.userAttributes.IBAN;
  },
  computed: {
    ...mapGetters("cognito", ["userAttributes"]),
    inputOptions() {
      return { placeholder: this.$t("yourPhoneNumber") };
    },
    salutations() {
      return SalutationList.map((sal) => {
        return {
          text: this.$t(sal.value),
          value: sal.value,
        };
      });
    },
    salutation: {
      get() {
        return this.userAttributes.salutation;
      },
      set(val) {
        this.setUserAttribute({ key: "salutation", value: val });
      },
    },
    title: {
      get() {
        return this.userAttributes.title;
      },
      set(val) {
        this.setUserAttribute({ key: "title", value: val });
      },
    },
    firstName: {
      get() {
        return this.userAttributes.firstName;
      },
      set(val) {
        this.setUserAttribute({ key: "firstName", value: val });
      },
    },
    lastName: {
      get() {
        return this.userAttributes.lastName;
      },
      set(val) {
        this.setUserAttribute({ key: "lastName", value: val });
      },
    },
    contactMail: {
      get() {
        return this.userAttributes.contactMail;
      },
      set(val) {
        //this.setUserAttribute({key: "contactMail", value: val});
      },
    },
    birthdate: {
      get() {
        return this.userAttributes.birthdate;
      },
      set(val) {
        this.setUserAttribute({ key: "birthdate", value: val });
      },
    },
    phoneNumber: {
      get() {
        return this.userAttributes.phoneNumber;
      },
      set(val) {
        this.setUserAttribute({ key: "phoneNumber", value: val });
      },
    },
    personsInHousehold: {
      get() {
        return this.userAttributes.personsInHousehold;
      },
      set(val) {
        this.setUserAttribute({ key: "personsInHousehold", value: val });
      },
    },
    areaForHousehold: {
      get() {
        return this.userAttributes.areaForHousehold;
      },
      set(val) {
        this.setUserAttribute({ key: "areaForHousehold", value: val });
      },
    },

    BIC: {
      get() {
        return this.userAttributes.BIC;
      },
      set(val) {
        this.setUserAttribute({ key: "BIC", value: val });
      },
    },
  },
  methods: {
    ...mapActions("cognito", ["saveAttributes"]),
    ...mapMutations(["setSnackbar"]),
    ...mapMutations("cognito", ["setUserAttribute"]),

    async save() {
      console.log("saving");
      this.loading = true;
      try {
        await this.saveAttributes();
        this.setSnackbar({
          type: "success",
          msg: this.$t("savingSuccess"),
        });
      } catch (res) {
        console.error(res);
        this.setSnackbar({
          type: "error",
          msg: this.$t("savingError"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    IBAN(val) {
      this.setUserAttribute({ key: "IBAN", value: val });
    },
  },
};
</script>
