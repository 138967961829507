import Vue from "vue";
import { MutationTree } from "vuex";
import { CognitoModuleState, UserAttributes, UserAttributeKey } from "./cognito.types";
import { CognitoUser } from "amazon-cognito-identity-js";
import Auth from "@aws-amplify/auth";
export default {
  setUser: (state: CognitoModuleState, user: CognitoUser) => {
    state.user = user;
    state.session = state.user?.getSignInUserSession()!;
    //this is necessary because CognitoUser.attributes does not exist ?!? Is there a better way -> //@npts-nocheck
    const _user = user as any;
    state.userAttributes = {
      ID: _user.attributes["sub"]?.trim(),
      email: user.getUsername()?.trim(),
      salutation: _user.attributes["custom:salutation"]?.trim(),
      identityId: _user.attributes["custom:identityId"]?.trim(),
      title: _user.attributes["custom:title"]?.trim(),
      firstName: _user.attributes.given_name?.trim(),
      lastName: _user.attributes.family_name?.trim(),
      contactMail: _user.attributes.email?.trim(),
      birthdate: _user.attributes.birthdate?.trim(),
      phoneNumber: _user.attributes.phone_number?.trim(),
      personsInHousehold: _user.attributes["custom:persons_in_household"]?.trim(),
      areaForHousehold: _user.attributes["custom:area_for_household"]?.trim(),
      IBAN: _user.attributes["custom:IBAN"]?.trim(),
      BIC: _user.attributes["custom:BIC"]?.trim() || "",
    } as UserAttributes;
  },
  unsetUser: (state: CognitoModuleState, user: CognitoUser) => {
    state.user = user;
  },

  setUsers: (state: CognitoModuleState, users: Array<any>) => {
    Vue.set(state, "users", users);
  },

  setUserAttribute: (state: CognitoModuleState, attribute: { key: UserAttributeKey; value: any }) => {
    state.userAttributes[attribute.key] = attribute.value;
  },
} as MutationTree<CognitoModuleState>;
