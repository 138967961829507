import Vue from "vue";
import * as AWS from "aws-sdk";
import { Module } from "vuex";
import { providerResource } from "@/api";
import { ProviderState, Provider, Actions, Getters, UploadResult, ProviderDocumentKey } from "./provider.types";
import { Actions as BaseActions } from "..";
import { set, add } from "../helpers";
import { DocumentDto } from "./documents.dto";

const updateDocument = (
  documents: DocumentDto[],
  key: ProviderDocumentKey,
  upload: AWS.S3.ManagedUpload.SendData | null
) => {
  if (upload != null) {
    const index = documents.findIndex((doc) => doc.key === key);
    documents[index].path = upload.Location;
  }
};

export const providerModule: Module<ProviderState, any> = {
  namespaced: true,
  state: { data: [] },
  mutations: {
    data: set("data"),
    add: add("data"),
    delete: (state, provider: Provider) => {
      state.data.splice(
        state.data.findIndex((o) => o.ID === provider.ID),
        1
      );
    },
    update: (state: ProviderState, payload: Provider) => {
      const index = state.data.findIndex((ct) => ct.ID === payload.ID);
      if (index != -1) {
        state.data[index] = payload;
        Vue.set(state.data, index, payload);
      }
    },
  },
  actions: {
    async [Actions.load]({ commit }) {
      const provider = (await providerResource.get("")).data;
      commit("data", provider);
    },

    async [Actions.createProvider](
      { commit, dispatch },
      provider: {
        name: string;
        website: string;
        file: File;
        agbFile: File;
        cancellationFile: File;
        dsgFile: File;
        sepaFile: File;
      }
    ) {
      const uploadResult: UploadResult = await dispatch(Actions.uploadProviderFiles, {
        name: provider.name,
        icon: provider.file,
        agbFile: provider.agbFile,
        cancellationFile: provider.cancellationFile,
        dsgFile: provider.dsgFile,
        sepaFile: provider.sepaFile,
      });
      console.log(uploadResult);
      const createProvider: Provider = {
        icon: uploadResult.icon!.Location,
        name: provider.name,
        website: provider.website,
        documents: [
          { fileName: provider.agbFile.name, path: uploadResult.agbs!.Location, key: "agb" },
          { fileName: provider.sepaFile.name, path: uploadResult.sepa!.Location, key: "sepa" },
          { fileName: provider.dsgFile.name, path: uploadResult.dsg!.Location, key: "dsg" },
          { fileName: provider.cancellationFile.name, path: uploadResult.cancellation!.Location, key: "cancellation" },
        ],
      };

      const created = (await providerResource.post("", createProvider)).data;
      commit("add", created);
      return created;
    },

    async [Actions.edit](
      { commit, dispatch },
      provider: Provider & { file: File; agbFile: File; cancellationFile: File; dsgFile: File; sepaFile: File }
    ) {
      const uploadResult: UploadResult = await dispatch(Actions.uploadProviderFiles, {
        name: provider.name,
        icon: provider.file,
        agbFile: provider.agbFile,
        cancellationFile: provider.cancellationFile,
        dsgFile: provider.dsgFile,
        sepaFile: provider.sepaFile,
      });

      let updatedDouments: DocumentDto[] = [];
      if (provider.documents?.length) {
        updatedDouments = [...provider.documents];
        updateDocument(updatedDouments, "agb", uploadResult.agbs);
        updateDocument(updatedDouments, "sepa", uploadResult.sepa);
        updateDocument(updatedDouments, "dsg", uploadResult.dsg);
        updateDocument(updatedDouments, "cancellation", uploadResult.cancellation);
      } else {
        updatedDouments = [
          { fileName: provider.agbFile.name, path: uploadResult.agbs!.Location, key: "agb" },
          { fileName: provider.sepaFile.name, path: uploadResult.sepa!.Location, key: "sepa" },
          { fileName: provider.dsgFile.name, path: uploadResult.dsg!.Location, key: "dsg" },
          { fileName: provider.cancellationFile.name, path: uploadResult.cancellation!.Location, key: "cancellation" },
        ];
      }

      console.log(updatedDouments);
      const created = (
        await providerResource.put("", {
          ...provider,
          icon: uploadResult.icon ? uploadResult.icon.Location : provider.icon,
          documents: updatedDouments,
        })
      ).data;

      commit("update", created);
      return created;
    },

    async [Actions.delete]({ commit }, provider: Provider) {
      await providerResource.delete(`/${provider.ID}`);
      commit("delete", provider);
    },

    async [Actions.uploadProviderFiles](
      { dispatch },
      provider: {
        name: string;
        icon: File;
        agbFile: File;
        cancellationFile: File;
        dsgFile: File;
        sepaFile: File;
      }
    ) {
      const awsCredentials: AWS.CognitoIdentityCredentials = await dispatch(BaseActions.updateCredentials, null, {
        root: true,
      });
      const s3 = new AWS.S3({});

      const upload: UploadResult = {
        agbs: null,
        icon: null,
        sepa: null,
        dsg: null,
        cancellation: null,
      };

      if (provider.icon) {
        const iconUpload = s3.upload({
          Bucket: "dev.images.centralize.at",
          Key: `provider/${provider.name.toLowerCase()}_${provider.icon.name}`,
          Body: provider.icon,
        });
        iconUpload.on("httpUploadProgress", (progress) => {});
        upload.icon = await iconUpload.promise();
      }
      if (provider.cancellationFile) {
        const cancellationUpload = s3.upload({
          Bucket: "dev.images.centralize.at",
          Key: `provider/${provider.name.toLowerCase()}_${provider.cancellationFile.name}`,
          Body: provider.cancellationFile,
        });
        cancellationUpload.on("httpUploadProgress", (progress) => {});
        upload.cancellation = await cancellationUpload.promise();
      }

      if (provider.dsgFile) {
        const dsgUpload = s3.upload({
          Bucket: "dev.images.centralize.at",
          Key: `provider/${provider.name.toLowerCase()}_${provider.dsgFile.name}`,
          Body: provider.dsgFile,
        });
        dsgUpload.on("httpUploadProgress", (progress) => {});
        upload.dsg = await dsgUpload.promise();
      }
      if (provider.sepaFile) {
        const sepaUpload = s3.upload({
          Bucket: "dev.images.centralize.at",
          Key: `provider/${provider.name.toLowerCase()}_${provider.sepaFile.name}`,
          Body: provider.sepaFile,
        });
        sepaUpload.on("httpUploadProgress", (progress) => {});
        upload.sepa = await sepaUpload.promise();
      }

      if (provider.agbFile) {
        const agbUpload = s3.upload({
          Bucket: "dev.images.centralize.at",
          Key: `provider/${provider.name.toLowerCase()}_${provider.agbFile.name}`,
          Body: provider.agbFile,
        });
        agbUpload.on("httpUploadProgress", (progress) => {});
        upload.agbs = await agbUpload.promise();
      } else {
      }
      return upload;
    },
  },
  getters: {
    [Getters.providers]: (state: ProviderState) => state.data,
    [Getters.providerById]: (state: ProviderState) => (id: string) => state.data.find((p) => p.ID === id),
  },
};
