













import Vue from "vue";
import Component from "vue-class-component";
import { ActionMethod } from "vuex";
import { providersNamesapce, contractsNamespace, cognitoNamespace } from "@/store";
import ContractChangeOptionsData from "./ContractChangeOptionsData.vue";
import {
  Contract,
  ContractChangeOption,
  Actions,
  ContractType,
  ContractPriceModel,
  PaymentType,
  EconomicalDecoration,
  BillingCyles,
} from "@/store/modules/contract.types";
import { ContractAware } from "../../mixins/ContractAware";

@Component({
  components: {
    ContractChangeOptionsData,
  },
  props: {
    editable: {
      type: [Boolean, String],
      default: true,
    },
  },
})
export default class ContractChangeOptionDialog extends ContractAware {
  private dialog: boolean = false;
  private edit: boolean = false;

  private changeOption: ContractChangeOption = {};
  show(changeOption: ContractChangeOption | null, contract: Contract) {
    this.dialog = true;
    if (changeOption) {
      this.edit = true;
      this.changeOption = changeOption;
    }
  }

  async submitChangeOption(changeOption: ContractChangeOption) {
    await this.updateChangeOptions({
      contract: this.contract,
      changeOption: changeOption,
    });
  }
}
