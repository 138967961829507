<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="true"
    :right="$vuetify.rtl"
    :src="require('@/assets/sidebar-1.jpg')"
    mobile-breakpoint="960"
    :expand-on-hover="expandOnHover"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">C</span>
          <span class="logo-normal">Centralize</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <template v-if="isLoggedIn">
      <v-divider class="mb-1" />

      <v-list dense nav>
        <base-item :item="profile" />
      </v-list>
      <v-divider class="mb-2" />
    </template>

    <v-list expand nav>
      <template v-for="(item, i) in items">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item"> </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { Actions, cognito, cognitoNamespace, contracts } from "@/store";
// Utilities
import { mapState } from "vuex";
import { cognitoModule } from "@/store/modules/cognito";
import { Getters, Actions as CognitoActions } from "@/store/modules/cognito/cognito.types";
import { Contract, ContractType, Getters as ContractGetters } from "@/store/modules/contract.types";
import { getGravatarHash, getGravatarUrl, getGravatarAvatar } from "gravatar-utils";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    gravatar: true,
  }),

  computed: {
    ...cognito.mapGetters([Getters.userAttributes, Getters.isLoggedIn, Getters.isAdminOrCentralizeUser]),
    ...contracts.mapGetters([ContractGetters.contractsByType]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.dispatch(Actions.setDrawer, val);
      },
    },

    contractsSidebarItem() {
      const powerContracts = this.contractsByType(ContractType.POWER);
      const gasContracts = this.contractsByType(ContractType.GAS);
      const children = [
        {
          title: this.$t("create-contract"),
          to: "create",
        },
      ];

      if (gasContracts.length > 0) {
        children.push({ to: `${gasContracts[0].ID}`, title: this.$t(ContractType.GAS) });
      }

      if (powerContracts.length > 0) {
        children.push({ to: `${powerContracts[0].ID}`, title: this.$t(ContractType.POWER) });
      }
      return {
        group: "/contracts",
        icon: "mdi-clipboard-text",
        title: this.$t("contracts"),
        requiresAuth: true,
        children,
      };
    },

    items() {
      return [
        {
          icon: "mdi-view-dashboard",
          title: this.$t("dashboard"),
          requiresAuth: true,
          to: "/",
        },
        this.contractsSidebarItem,
        {
          icon: "mdi-av-timer",
          title: this.$t("providers"),
          requiresAuth: true,
          requiresAdminOrCentralize: true,
          to: "/admin/providers",
        },
        {
          icon: "mdi-archive",
          title: this.$t("archive"),
          requiresAuth: true,
          requiresAdminOrCentralize: false,
          to: "/archive",
        },
        // {
        //   group: "/documents",
        //   icon: "mdi-file-document",
        //   title: this.$t("documents"),
        //   children: [
        //     {
        //       title: this.$t("create-document"),
        //       to: "",
        //     },
        //     // todo this is going to be
        //     {
        //       title: this.$t("reisepass"),
        //       to: "",
        //     },
        //   ],
        // },

        {
          to: "/faq",
          icon: "mdi-frequently-asked-questions",
          title: this.$t("how-does-this-work"),
          requiresAuth: false,
        },
        {
          to: "/contact",
          icon: "mdi-message-text",
          title: this.$t("contact"),
          requiresAuth: false,
        },
        {
          icon: "mdi-logout",
          title: this.$t("logout"),
          requiresAuth: true,
          action: async () => {
            await this.signOut();
            this.$router.push("/auth/signin");
          },
        },
      ].filter((elem) => {
        if (elem.requiresAdminOrCentralize) return this.isAdminOrCentralizeUser;
        if (elem.requiresAuth) return this.isLoggedIn;
        return true;
      });
    },

    profile() {
      if (this.gravatar) {
        const gravatar = getGravatarAvatar(this.userAttributes.contactMail) + "d=identicon";
        return {
          avatar: gravatar,
          avatarError: this.gravatarImageFailed.bind(this),
          title: `${this.userAttributes.firstName} ${this.userAttributes.lastName}`,
          to: "/user/profile",
        };
      }
      const name = `${this.userAttributes.firstName} ${this.userAttributes.lastName}`;
      return {
        text: name,
        title: name,
        to: "/user/profile",
      };
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    ...cognito.mapActions([CognitoActions.signOut]),
    gravatarImageFailed() {
      this.gravatar = false;
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
