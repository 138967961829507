import Vue from "vue";
import { mapState } from "vuex";
import { addresss, cognito, contracts, providers } from "../../store";
import { Actions as ContractActions } from "@/store/modules/contract.types";
import { Actions as ProviderActions } from "@/store/modules/provider.types";
import { Actions as AddressActions } from "@/store/modules/address.types";
import { Actions as CognitoActions, Getters } from "@/store/modules/cognito/cognito.types";

export default Vue.extend({
  computed: {
    ...mapState(["isReady"]),
    ...cognito.mapGetters([Getters.isAdminOrCentralizeUser]),
  },

  methods: {
    ...contracts.mapActions({ loadContracts: ContractActions.load }),
    ...providers.mapActions({ loadProviders: ProviderActions.load }),
    ...cognito.mapActions({ loadUsers: CognitoActions.loadUsers }),
    ...addresss.mapActions({ loadAddress: AddressActions.load }),
  },

  async mounted() {
    if (this.isReady) {
      const promises = [this.loadProviders(), this.loadContracts(), this.loadAddress()];
      if (this.isAdminOrCentralizeUser) {
        promises.push(this.loadUsers());
      }
      await Promise.all(promises);

      this.$emit("ready");
    }
  },
  watch: {
    async isReady(val) {
      if (val) {
        this.$emit("ready");
      }
    },
  },
  render(): any {
    return this.$scopedSlots.default!({});
  },
});
