import store from "@/store";

export default class AuthMiddleware {
  async onRequest(config: any) {
    const loggedIn = store.getters["cognito/isLoggedIn"];
    let session = store.getters["cognito/session"];
    if (session.accessToken.payload.exp < new Date().getTime() / 1000) {
      await store.dispatch("cognito/fetchSession");
      session = store.getters["cognito/session"];
    }
    if (loggedIn) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${session.idToken.jwtToken}`,
      };
    }
    return config;
  }
  onSync(promise: any) {
    return promise;
  }
  onResponse(response: any) {
    return response;
  }
  onResponseError(response: any) {
    return Promise.reject(response);
  }
}
