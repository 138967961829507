






import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Provide } from "vue-property-decorator";
@Component({
  provide() {
    return {
      agree: (this as any).agree,
      cancel: (this as any).cancel,
    };
  },
})
export default class AwaitableDialog extends Vue {
  @Prop({ type: [String, Boolean], default: true }) value: string | boolean | undefined;
  dialog = false;
  resolve: any = null;
  reject: any = null;

  open() {
    this.dialog = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  agree() {
    this.resolve(true);
    this.dialog = false;
  }
  cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
