



import Vue from "vue";
import Autonumeric from "autonumeric";

export default Vue.extend({
  props: {
    decimalPlaces: {
      type: [Number, String],
      default: 2,
    },
  },
  mounted() {
    new Autonumeric((this.$refs.field as any).$refs.input, null, {
      decimalPlaces: this.$props.decimalPlaces,
      outputFormat: "number",
      digitGroupSeparator: "",
    });
  },
});
