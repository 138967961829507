import { CognitoUser } from "@aws-amplify/auth";
import { UserAttributes } from "./cognito.types";

const state = () => {
  return {
    session: {},
    user: {} as CognitoUser,
    userAttributes: {} as UserAttributes
  };
};
export default state;
