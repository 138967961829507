




































import { ContractStateIndex } from "@/store/modules/contract.types";
import Component from "vue-class-component";
import { ContractAware } from "../../mixins/ContractAware";

@Component({})
export default class ContractStatus extends ContractAware {
  get contractTimeline() {
    return [
      {
        color: "red lighten-1",
        icon: "mdi-cloud-upload",
        title: "UPLOADED",
        text: "UPLOADED",
      },
      {
        color: "green lighten-1",
        icon: "mdi-information-variant",
        title: "CONTRACT_DATA_ADDED",
        text: "CONTRACT_DATA_ADDED",
      },
      {
        color: "blue lighten-2",
        icon: "mdi-message-alert",
        title: "CHANGE_OPTIONS_ADDED",
        text: "CHANGE_OPTIONS_ADDED",
      },
      {
        color: "orange lighten-1",
        icon: "mdi-checkbox-marked-circle-outline",
        title: "CHANGE_OPTION_SELECTED",
        text: "CHANGE_OPTION_SELECTED",
      },
      {
        color: "blue lighten-2",
        icon: "mdi-swap-horizontal",
        title: "CONTRACT_CHANGE_IN_PROGRESS",
        text: "CONTRACT_CHANGE_IN_PROGRESS",
      },
      {
        color: "orange lighten-1",
        icon: "mdi-check-all",
        title: "CONTRACT_CHANGED",
        text: "CONTRACT_CHANGED",
      },
      {
        color: "orange lighten-1",
        icon: "mdi-alert",
        title: "CONTRACT_CHANGE_FAILED",
        text: "CONTRACT_CHANGE_FAILED",
      },
      {
        color: "blue lighten-2",
        icon: "mdi-archive",
        title: "ARCHIVED",
        text: "ARCHIVED",
      },
    ].splice(0, ContractStateIndex[this.contract.contractState!]);
  }
}
