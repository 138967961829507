<template>
  <v-container fluid>
    <ContractDashboard v-if="isLoggedIn" />
  </v-container>
</template>

<script>
// @ is an alias to /src
import ContractDashboard from "@/components/ContractDashboard/ContractDashboard";
import { mapGetters } from "vuex";

export default {
  name: "home",
  components: {
    ContractDashboard,
  },
  computed: {
    ...mapGetters("cognito", ["isLoggedIn"]),
  },
};
</script>
