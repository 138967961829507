import { DocumentDto } from "./documents.dto";

export enum Actions {
  load = "load",
  lock = "lock",
  unlock = "unlock",
  update = "update",
  updateChangeOptions = "updateChangeOptions",
  deleteChangeOption = "deleteChangeOption",
  selectChangeOption = "selectChangeOption",
  setContractState = "setContractState",
  uploadContract = "uploadContract",
  uploadMultiple = "uploadMultiple",
  createContract = "createContract",
  getDownloadUrl = "getDownloadUrl",
  addAdditionalMeter = "addAdditionalMeter",
  removeAdditionalMeter = "removeAdditionalMeter",
  getDocumentUrl = "getDocumentUrl",
  addFiles = "addFiles",
  deleteFile = "deleteFile",
}

export enum Mutations {
  setEditContractId = "setEditContractId",
}

export enum Getters {
  contracts = "contracts",
  currentContract = "currentContract",
  archivedContracts = "archivedContracts",
  contractById = "contractById",
  contractsByType = "contractsByType",
  progress = "progress",
  contractDownloadUrl = "contractDownloadUrl",
  contractStateIndex = "contractStateIndex",
  overallCost = "overallCost",
  savings = "savings",
}

export interface ContractModuleState {
  data: Contract[];
  progress: number;
  editContractId: string | null;
}

export enum ContractState {
  UPLOADED = "UPLOADED",
  CONTRACT_DATA_ADDED = "CONTRACT_DATA_ADDED",
  CHANGE_OPTIONS_ADDED = "CHANGE_OPTIONS_ADDED",
  CHANGE_OPTION_SELECTED = "CHANGE_OPTION_SELECTED",
  CONTRACT_CHANGE_IN_PROGRESS = "CONTRACT_CHANGE_IN_PROGRESS",
  CONTRACT_CHANGED = "CONTRACT_CHANGED",
  CONTRACT_CHANGE_FAILED = "CONTRACT_CHANGE_FAILED",
  TRANSITIONED_CONTRACT = "TRANSITIONED_CONTRACT",
  ARCHIVED = "ARCHIVED",
}

export const ContractStateIndex = {
  [ContractState.UPLOADED]: 1,
  [ContractState.CONTRACT_DATA_ADDED]: 2,
  [ContractState.CHANGE_OPTIONS_ADDED]: 3,
  [ContractState.CHANGE_OPTION_SELECTED]: 4,
  [ContractState.CONTRACT_CHANGE_IN_PROGRESS]: 5,
  [ContractState.CONTRACT_CHANGED]: 6,
  [ContractState.CONTRACT_CHANGE_FAILED]: 7,
  [ContractState.TRANSITIONED_CONTRACT]: 8,
  [ContractState.ARCHIVED]: 9,
};

export enum ContractType {
  POWER = "POWER",
  GAS = "GAS",
}

export enum ContractPriceModel {
  STANDARD = "STANDARD",
  PRICE_GUARANTEE = "PRICE_GUARANTEE",
  FLOATING = "FLOATING",
}

export enum PaymentType {
  INVOICE = "INVOICE",
  DIRECT_DEBIT = "DIRECT_DEBIT",
}

export enum EconomicalDecoration {
  AT_NORM = "AT_NORM",
  GLOBAL_2000_NORM = "GLOBAL_2000_NORM",
}

export interface ContractStateTransition {
  previousState: Contract;
  currentState: Contract;
}

export class Contract {
  ID?: string;
  createdAt?: string;
  updatedAt?: string;
  name?: string;
  documents?: DocumentDto[];
  s3Path?: string;
  originalFilename?: string;
  changeAutomatically?: boolean;
  contractType?: ContractType;
  contractDuration?: number;
  cancellationPeriod?: number;
  singleInvoice?: boolean;
  paymentType?: PaymentType;
  billingCycle?: number;
  usagePerYear?: number;
  personsInHoushold?: number;
  pricePerUnit?: number;
  basePrice?: number;
  firstYearBonus?: number;
  networkCostPerYear?: number;
  provider?: string;
  contractNumber?: string;
  startDate?: string;
  endDate?: string;
  meterNumber?: string;
  additionalMeters?: MeterNumberUsage[];
  UID?: string;
  deliveryAddress?: string;
  billingAddress?: string;
  changeOptions?: ContractChangeOption[];
  priceModel?: ContractPriceModel;
  selectedChangeOption?: ContractChangeOption;
  contractState?: ContractState;
  economicalDecorations?: EconomicalDecoration[];
  totalPricePerYear?: number;
  totalUsage?: number;
  notes?: string;
  trackingNumber?: string;
  netPrice?: boolean;
}
export class Documents {
  path?: string;
  fileName?: string;
}
export class MeterNumberUsage {
  meterNumber?: string;
  usagePerYear?: number;
}
export class ContractChangeOption {
  ID?: string;
  provider?: string;
  tariffName?: string;
  link?: string;
  selected?: boolean;
  trackingNumber?: string;
  priceModel?: ContractPriceModel;
  contractDuration?: number;
  cancellationPeriod?: number;
  paymentType?: PaymentType;
  billingCycle?: number;
  economicalDecorations?: EconomicalDecoration[];
  pricePerUnit?: number;
  basePrice?: number;
  firstYearBonus?: number;
  networkCostPerYear?: number;
  usagePerYear?: number;
  singleInvoice?: boolean;
  totalPricePerYear?: number;
}

export const BillingCyles = [
  {
    duration: 1,
    key: "MONTHLY",
  },
  {
    duration: 3,
    key: "QUATERLY",
  },
  {
    duration: 6,
    key: "BIANNUALY",
  },
  {
    duration: 12,
    key: "ANNUALLY",
  },
];
