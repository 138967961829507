


























































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ContractAware } from "../../mixins/ContractAware";

import { Provider, Getters } from "@/store/modules/provider.types";
import { Getters as CognitoGetters } from "@/store/modules/cognito/cognito.types";
import {
  BillingCyles,
  Contract,
  ContractChangeOption,
  ContractPriceModel,
  ContractType,
  EconomicalDecoration,
  PaymentType,
} from "../../store/modules/contract.types";
import { cognitoNamespace, providersNamesapce } from "../../store";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ContractChangeOptionsData extends Vue {
  @providersNamesapce.Getter(Getters.providers) providers!: Provider[];
  @cognitoNamespace.Getter(CognitoGetters.isAdminOrCentralizeUser)
  isAdminOrCentralizeUser!: boolean;

  @Prop({ type: [String, Boolean], default: true }) readonly editable: string | boolean | undefined;

  @Prop({ type: Object as () => ContractChangeOption }) readonly changeOption: ContractChangeOption | undefined;

  private provider: string | null = null;
  private tariffName: string = "";
  private monthlyCost: number = 0;
  private link: string = "";

  async save() {
    this.$emit("submit", this.changeOption);
  }

  get contractTypes(): { text: string; value: ContractType }[] {
    return Object.values(ContractType).map((contractType) => {
      return {
        text: this.$t(contractType).toString(),
        value: contractType,
      };
    });
  }

  get priceModels(): { text: string; value: ContractPriceModel }[] {
    return Object.values(ContractPriceModel).map((priceModel) => {
      return {
        text: this.$t(priceModel).toString(),
        value: priceModel,
      };
    });
  }

  get paymentTypes(): { text: string; value: PaymentType }[] {
    return Object.values(PaymentType).map((paymentType) => {
      return {
        text: this.$t(paymentType).toString(),
        value: paymentType,
      };
    });
  }

  get ecoDecorations(): { text: string; value: EconomicalDecoration }[] {
    return Object.values(EconomicalDecoration).map((decoration) => {
      return {
        text: this.$t(decoration).toString(),
        value: decoration,
      };
    });
  }

  get billingCycles() {
    return BillingCyles;
  }
}
