















import Vue from "vue";
import CoreFooter from "./Footer.vue";
import AppBar from "./AppBar.vue";
import Component from "vue-class-component";
@Component({
  components: {
    CoreFooter,
    AppBar,
  },
})
export default class LoggedOutLayout extends Vue {}
