<template>
  <v-container>
    <v-row id="login" justify="center" style="width: 100%">
      <v-card class="elevation-4" style="width: 100%">
        <v-card-title>
          <base-subheading>{{ $t("imprint") }}</base-subheading>
        </v-card-title>

        <v-card-text>
          <p>
            Da uns Transparenz wichtig ist findet ihr hier alles rund um centralize.at und obendrein erfüllen wir damit
            auch die Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
            Offenlegungspflicht laut §25 Mediengesetz.
          </p>
          <p>
            centralize.at ist eine webbasierte Plattform, welche durch
          </p>
          <p>
            centralize GmbH
          </p>
          <p>
            Untere Landstraße 37
          </p>
          <p>
            3500 Krems an der Donau
          </p>
          <p>
            Österreich
          </p>
          <p>
            <strong>E-Mail: </strong>
            <a href="mailto:office@centralize.at">office@centralize.at</a>
          </p>
          <p>
            betrieben wird (“centralize”), welcher Verantwortlicher iSd Art 4 Z7 DSGVO ist.
          </p>
          <p>
            <strong>Haftung für Inhalte dieser Webseite</strong>
          </p>
          <p>
            Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle
            Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser
            Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
          </p>
          <p>
            Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu
            kontaktieren, Sie finden die Kontaktdaten im Impressum.
          </p>
          <p>
            <strong>Haftung für Links auf dieser Webseite</strong>
          </p>
          <p>
            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung
            für verlinkte Websites besteht laut
            <a
              href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&amp;Dokumentnummer=NOR40025813&amp;tid=221090187"
              target="_blank"
            >
              § 17 ECG
            </a>
            für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche
            Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns
            Rechtswidrigkeiten bekannt werden.
          </p>
          <p>
            Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden
            die Kontaktdaten im Impressum und erreichen uns unter
            <a href="mailto:office@centralize.at">office@centralize.at</a>.
          </p>
          <p>
            <strong>Urheberrechtshinweis</strong>
          </p>
          <p>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig,
            werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
          </p>
          <p>
            <strong>Bildernachweis</strong>
          </p>
          <p>
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
          </p>
          <p>
            Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
          </p>
          <ul>
            <li>
              Centralize GmbH
            </li>
          </ul>
          <p>
            <strong>EU-Streitschlichtung</strong>
          </p>
          <p>
            Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
            über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
          </p>
          <p>
            Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
            Kommission unter
            <a
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
              target="_blank"
            >
              http://ec.europa.eu/odr?tid=221090187
            </a>
            zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
          </p>
          <p>
            Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
