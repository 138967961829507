var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-loader-component',{on:{"ready":_vm.loadData}},[_c('v-container',[_c('v-dialog',{attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndUp ? '60%' : 'none'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"bottom":"64px","z-index":"5"},attrs:{"color":"primary","fab":"","fixed":"","bottom":"","right":"","dark":""}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',[_vm._v(_vm._s(_vm.$t(_vm.editing ? "editProvider" : "createProvider"))+" ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Website","error-messages":errors},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Icon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"error-messages":errors,"chips":"","multiple":"","label":"Provider Icon"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"AGB","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"error-messages":errors,"chips":"","multiple":"","label":"AGB"},model:{value:(_vm.agbFiles),callback:function ($$v) {_vm.agbFiles=$$v},expression:"agbFiles"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Datenschutzerklärung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"error-messages":errors,"chips":"","multiple":"","label":"Datenschutzerklärung"},model:{value:(_vm.dsgFiles),callback:function ($$v) {_vm.dsgFiles=$$v},expression:"dsgFiles"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Widerrufsbelehrung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"error-messages":errors,"chips":"","multiple":"","label":"Widerrufsbelehrung"},model:{value:(_vm.cancellationFiles),callback:function ($$v) {_vm.cancellationFiles=$$v},expression:"cancellationFiles"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"SEPA-Vollmacht","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"error-messages":errors,"chips":"","multiple":"","label":"SEPA-Vollmacht"},model:{value:(_vm.sepaFiles),callback:function ($$v) {_vm.sepaFiles=$$v},expression:"sepaFiles"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.creating,"color":"primary","disabled":invalid},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.editing ? "Save" : "Create"))])],1)]}}])})],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("provider"))+":")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.providers,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.icon,"height":"35","width":"100px","contain":"","position":"left"}})]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [_vm._l((item.documents),function(doc,index){return [_c('span',{key:doc.path,staticClass:"mr-1"},[_c('a',{attrs:{"href":doc.path,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t(doc.key)))]),_vm._v(_vm._s(index !== item.documents.length - 1 ? "," : "")+" ")])]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editProvider(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.isReferenced(item)},on:{"click":function($event){return _vm.deleteProvider(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }