import * as AWS from "aws-sdk";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import { ActionTree } from "vuex";
import { usersResource } from "@/api";
import router from "../../../router";
import { Actions as RootActions } from "../../";

export default {
  fetchCurrentAuthenticatedUser: ({ commit }) => {
    return Auth.currentAuthenticatedUser().then((user) => {
      commit("setUser", user);
      return user;
    });
  },
  fetchSession: async ({ commit }) => {
    await Auth.currentSession();
    const user = await Auth.currentUserPoolUser();
    commit("setUser", user);
    return user;
  },
  fetchJwtToken: async ({ commit }) => {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  },
  signInUser: async ({ commit, dispatch, state }, credentials) => {
    const user = await Auth.signIn(credentials.username, credentials.password);

    commit("setUser", user);

    const awsCredentials: AWS.CognitoIdentityCredentials = await dispatch(RootActions.updateCredentials, null, {
      root: true,
    });
    if (state.userAttributes.identityId != awsCredentials.identityId) {
      await Auth.updateUserAttributes(user, { "custom:identityId": awsCredentials.identityId });
    }
    return user;
  },
  answerCustomChallenge: async ({ commit }, credentials) => {
    // Auth.updateUserAttributes()
    const user = await Auth.sendCustomChallengeAnswer(credentials.user, credentials.answer);
    commit("setUser", user);
    return user;
  },
  registerUser: async ({ commit }, credentials) => {
    // TODO: Ensure I'm attribute agnostic
    const user = await Auth.signUp({
      username: credentials.username,
      password: credentials.password,
      attributes: credentials.attributes,
    });
    return user;
  },
  confirmUser: async (_, data) => {
    await Auth.confirmSignUp(data.username, data.code);
  },
  resendConfirmation: async (_, data) => {
    await Auth.resendSignUp(data.username);
  },
  resendSignUp: async (_, data) => {
    await Auth.resendSignUp(data.username);
  },
  forgotPassword: async (_, data) => {
    await Auth.forgotPassword(data.username);
  },
  changePassword: async (_, data) => {
    await Auth.forgotPasswordSubmit(data.username, data.code, data.newPassword);
  },
  signOut: async ({ commit, getters }) => {
    if (!getters.isLoggedIn) {
      throw new Error("User not logged in.");
    }
    await Auth.signOut();
    localStorage.clear();
    localStorage.removeItem("vuex");
    commit("unsetUser", {});
    if (localStorage) localStorage.removeItem("USER");
  },
  saveAttributes: async ({ state }) => {
    let attributes: any = {};

    if (state.userAttributes.salutation) {
      attributes["custom:salutation"] = state.userAttributes.salutation;
    }
    if (state.userAttributes.title) {
      attributes["custom:title"] = state.userAttributes.title;
    }
    if (state.userAttributes.firstName) {
      attributes["given_name"] = state.userAttributes.firstName;
    }
    if (state.userAttributes.lastName) {
      attributes["family_name"] = state.userAttributes.lastName;
    }
    if (state.userAttributes.birthdate) {
      attributes["birthdate"] = state.userAttributes.birthdate;
    }

    if (state.userAttributes.phoneNumber) {
      attributes["phone_number"] = state.userAttributes.phoneNumber?.replace(/\s/g, "");
    }

    if (state.userAttributes.personsInHousehold) {
      attributes["custom:persons_in_household"] = state.userAttributes.personsInHousehold?.toString();
    }
    if (state.userAttributes.areaForHousehold) {
      attributes["custom:area_for_household"] = state.userAttributes.areaForHousehold?.toString();
    }
    if (state.userAttributes.IBAN) {
      attributes["custom:IBAN"] = state.userAttributes.IBAN?.toString();
    }
    if (state.userAttributes.BIC) {
      attributes["custom:BIC"] = state.userAttributes.BIC?.toString();
    }

    try {
      await Auth.updateUserAttributes(state.user, attributes);
    } catch (e) {
      console.log(e);
    }
  },

  loadUsers: async ({ commit }) => {
    const users = (await usersResource.get("")).data;
    commit("setUsers", users);
  },
} as ActionTree<any, any>;
