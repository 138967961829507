import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { UserAttributeList } from "aws-sdk/clients/inspector";

export interface CognitoModuleState {
  user: CognitoUser;
  session: CognitoUserSession;
  userAttributes: UserAttributes;
  users: Array<any>;
}

export enum Getters {
  isLoggedIn = "isLoggedIn",
  isAdmin = "isAdmin",
  isCentralizeUser = "isCentralizeUser",
  isAdminOrCentralizeUser = "isAdminOrCentralizeUser",
  userForId = "userForId",
  userAttributeForId = "userAttributeForId",
  userId = "userId",
  userAttributes = "userAttributes",
  userProfileValidForContractChange = "userProfileValidForContractChange",
}

export enum Actions {
  signOut = "signOut",
  loadUsers = "loadUsers",
}

export interface UserAttributes {
  ID: string;
  identityId: string;
  salutation: any;
  title: any;
  firstName: any;
  lastName: any;
  contactMail: any;
  birthdate: any;
  phoneNumber: any;
  personsInHousehold: any;
  areaForHousehold: any;
  emailHook: any;
  email: string;
  IBAN: any;
  BIC: any;
}

export type UserAttributeKey = keyof UserAttributes;

interface Salutation {
  text: string;
  value: string;
}

enum SalutationValue {
  company = "company",
  male = "male",
  female = "female",
}

export const SalutationList: Salutation[] = [
  {
    text: "Dear colleagues",
    value: SalutationValue.company,
  },
  {
    text: "Dear Sir",
    value: SalutationValue.male,
  },
  {
    text: "Dear Madam",
    value: SalutationValue.female,
  },
];
