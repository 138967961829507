var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('snackbar'),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-row',{attrs:{"id":"login","justify":"center"}},[_c('v-card',{staticClass:"elevation-4"},[_c('v-card-title',{staticStyle:{"word-break":"normal"}},[_c('v-row',{staticClass:"pt-10",attrs:{"align":"center","justify":"center"}},[_c('base-heading',{staticClass:"text-center grey--text text--darken-2 ",attrs:{"text":_vm.$t('registerHeadline')}})],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","md":"6"}},[_c('v-list',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"large":"","color":"pink"}},[_vm._v("mdi-currency-eur")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('subheading',[_vm._v(_vm._s(_vm.$t("saveMoney")))])],1),_c('v-list-item-subtitle',[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("saveMoneyText")))])])],1)],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"large":"","color":"purple"}},[_vm._v("mdi-information-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('subheading',[_vm._v(_vm._s(_vm.$t("overview")))])],1),_c('v-list-item-subtitle',[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("overviewText")))])])],1)],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"large":"","color":"blue lighten-2"}},[_vm._v("mdi-heart")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('subheading',[_vm._v(_vm._s(_vm.$t("organize")))])],1),_c('v-list-item-subtitle',[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("organizeText")))])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto","md":"6"}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('firstname') + '*',"error-messages":errors,"autocomplete":"first-name","prepend-icon":"mdi-account"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('lastname') + '*',"error-messages":errors,"autocomplete":"family-name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('email'),"rules":"required|email","vid":"originalEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('email') + '*',"error-messages":errors,"autocomplete":"username","prepend-icon":"mdi-email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('emailConfirmation'),"rules":"required|email|confirmed:@originalEmail","vid":"emailConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('emailConfirmation') + '*',"error-messages":errors,"autocomplete":"username"},model:{value:(_vm.emailConfirmation),callback:function ($$v) {_vm.emailConfirmation=$$v},expression:"emailConfirmation"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('password'),"rules":"required|verify_password|min:8","vid":"originalPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"label":_vm.$t('password') + '*',"name":"password","autocomplete":"new-password","prepend-icon":"mdi-lock"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('passwordConfirmation'),"rules":"required|confirmed:@originalPassword","vid":"passwordConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"append-icon":_vm.showConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmation ? 'text' : 'password',"label":_vm.$t('passwordConfirmation') + '*',"name":"password","autocomplete":"new-password"},on:{"click:append":function($event){_vm.showConfirmation = !_vm.showConfirmation}},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-checkbox',{attrs:{"outlined":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("accept"))+" "),_c('router-link',{attrs:{"to":"/agb"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{attrs:{"href":href,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t("agbAccept")))]),_vm._v(" * ")]}}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.agb),callback:function ($$v) {_vm.agb=$$v},expression:"agb"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-checkbox',{attrs:{"outlined":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("accept"))+" "),_c('router-link',{attrs:{"to":"/dsgvo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{attrs:{"href":href,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t("dsgAccept")))]),_vm._v(" * ")]}}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.dsg),callback:function ($$v) {_vm.dsg=$$v},expression:"dsg"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"disabled":invalid || !_vm.agb || !_vm.dsg,"color":"primary","rounded":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }