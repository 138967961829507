












































































import Vue from "vue";
import Component from "vue-class-component";
import { addressesNamespace, cognitoNamespace } from "@/store";
import { Getters, UserAttributes } from "@/store/modules/cognito/cognito.types";
import { Prop } from "vue-property-decorator";
import { Address, Getters as AddressGetters, Actions } from "@/store/modules/address.types";

@Component({})
export default class UserInfo extends Vue {
  @Prop({ type: String }) UID!: string;
  @Prop({ type: [String, Boolean] }) value!: string | boolean;

  @cognitoNamespace.Getter(Getters.userForId) userForId!: (uid: string) => any;
  @cognitoNamespace.Getter(Getters.isAdminOrCentralizeUser) isAdminOrCentralizeUser!: boolean;
  @cognitoNamespace.Getter(Getters.userAttributes) userAttributes!: UserAttributes;

  @addressesNamespace.Getter(AddressGetters.primaryBillingAddress) primaryBillingAddress!: Address;
  @addressesNamespace.Getter(AddressGetters.primaryShippingAddress) primaryShippingAddress!: Address;
  @addressesNamespace.Action(Actions.loadAddressByUser) loadAddressByUser!: (uid: string) => Address[];

  billingAddress = null;

  async created() {
    if (this.UID != this.userAttributes.ID) {
      await this.loadAddressByUser(this.UID);
    }
  }

  get userData() {
    if (this.isAdminOrCentralizeUser) {
      return this.userForId(this.UID);
    }
    return this.userAttributes;
  }
}
