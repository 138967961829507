var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('base-subheading',{attrs:{"subheading":_vm.$t('changeoptionsheadline')}},[_vm._v(" "+_vm._s(_vm.$t("changeoptionssubheadline"))+" ")]),_c('v-container',{attrs:{"fluid":""}},[_c('base-awaitable-dialog',{ref:"agreeChangeDialog",attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndUp ? '50%' : 'none',"scrollable":""}},[_c('agree-contract-change-dialog',{attrs:{"selectedChangeOption":_vm.selectedChangeOption}})],1),(
          _vm.isAdminOrCentralizeUser ||
            _vm.contractStateIndex(_vm.contract.contractState) > _vm.contractStateIndex('CONTRACT_DATA_ADDED')
        )?_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.contract.changeOptions),function(changeOption){return _c('v-col',{key:changeOption.ID,attrs:{"cols":"12"}},[_c('v-card',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("tariffName")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(changeOption.tariffName))])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("providers")))]),_c('v-list-item-subtitle',[(changeOption.provider)?_c('v-img',{attrs:{"src":_vm.providerById(changeOption.provider).icon,"height":"25","width":"100%","contain":"","position":"left"}}):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("price")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("currency")(changeOption.totalPricePerYear))+" ("),_c('span',{class:_vm.contract.totalPricePerYear - changeOption.totalPricePerYear > 0 ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.contract.totalPricePerYear - changeOption.totalPricePerYear))))]),_vm._v(") ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("duration")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(changeOption.contractDuration)+" "+_vm._s(_vm.$t("months")))])],1),_c('v-list-item',[_c('v-row',[(
                    _vm.isAdminOrCentralizeUser &&
                      _vm.contract.contractState !== 'CHANGE_OPTION_SELECTED' &&
                      _vm.contract.contractState !== 'CHANGE_OPTIONS_ADDED' &&
                      _vm.contract.contractState !== 'CONTRACT_CHANGE_IN_PROGRESS'
                  )?_c('v-col',[_c('v-btn',{on:{"click":function($event){return _vm.deleteChangeOption({ contract: _vm.contract, changeOption: changeOption })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(_vm._s(_vm.$t("delete"))+" ")],1)],1):_vm._e(),_c('v-col',[_c('contract-change-option-dialog',{attrs:{"editable":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var show = ref.show;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return show(changeOption, _vm.contract)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information-outline")]),_vm._v(_vm._s(_vm.$t("details"))+" ")],1)]}}],null,true)})],1),(
                    (!_vm.isAdminOrCentralizeUser || _vm.contract.UID === _vm.userId) &&
                      _vm.contract.contractState !== 'CHANGE_OPTION_SELECTED' &&
                      _vm.contract.contractState !== 'CONTRACT_CHANGE_IN_PROGRESS'
                  )?_c('v-col',[_c('v-btn',{attrs:{"target":"_blank","color":"primary","loading":_vm.selectingId === changeOption.ID},on:{"click":function($event){return _vm.selectContractChangeOption(changeOption)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information-outline")]),_vm._v(_vm._s(_vm.$t("selectChangeOption"))+" ")],1)],1):_vm._e(),(
                    _vm.isAdminOrCentralizeUser &&
                      _vm.contract.contractState !== 'CHANGE_OPTION_SELECTED' &&
                      _vm.contract.contractState !== 'CHANGE_OPTIONS_ADDED' &&
                      _vm.contract.contractState !== 'CONTRACT_CHANGE_IN_PROGRESS'
                  )?_c('v-col',[_c('contract-change-option-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var show = ref.show;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return show(changeOption, _vm.contract)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(_vm._s(_vm.$t("edit"))+" ")],1)]}}],null,true)})],1):_vm._e(),(_vm.contract.contractState === 'CHANGE_OPTION_SELECTED')?_c('v-col',[(_vm.isAdminOrCentralizeUser && _vm.contract.selectedChangeOption.ID === changeOption.ID)?[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":_vm.$t('trackingNumber'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('trackingNumber') + '*',"error-messages":errors},model:{value:(changeOption.trackingNumber),callback:function ($$v) {_vm.$set(changeOption, "trackingNumber", $$v)},expression:"changeOption.trackingNumber"}}),_c('v-btn',{attrs:{"loading":_vm.changeProcessContract,"disabled":invalid},on:{"click":_vm.setContractChangeInProgress}},[_vm._v(" "+_vm._s(_vm.$t("setChangeInProgress"))+" ")])]}}],null,true)})]}}],null,true)})]:(_vm.contract.selectedChangeOption.ID === changeOption.ID)?_c('v-btn',{attrs:{"disabled":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(_vm._s(_vm.$t("selected"))+" ")],1):_vm._e()],2):_vm._e(),(
                    _vm.isAdminOrCentralizeUser &&
                      _vm.contract.contractState === 'CONTRACT_CHANGE_IN_PROGRESS' &&
                      _vm.contract.selectedChangeOption.ID === changeOption.ID
                  )?[_c('v-col',[_c('v-btn',{attrs:{"block":"","loading":_vm.submittingContractChange},on:{"click":_vm.submitContractChange}},[_vm._v(" "+_vm._s(_vm.$t("setContractChanged"))+" ")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","loading":_vm.failChangeContract},on:{"click":_vm.submitContractChangeFailed}},[_vm._v(" "+_vm._s(_vm.$t("setContractChangedFailed"))+" ")])],1),_c('v-col')]:(
                    !_vm.isAdminOrCentralizeUser &&
                      _vm.contract.contractState === 'CONTRACT_CHANGE_IN_PROGRESS' &&
                      _vm.contract.selectedChangeOption.ID === changeOption.ID
                  )?[_c('v-col',[_c('v-btn',{attrs:{"disabled":""}},[_c('v-progress-circular',{staticStyle:{"transform":"scale(0.55)"},attrs:{"indeterminate":"","color":"primary"}}),_vm._v(" "+_vm._s(_vm.$t("setContractChangedInProgress"))+" ")],1)],1)]:_vm._e()],2)],1)],1)],1)}),1):_vm._e()],1)],1),(_vm.editable)?_c('v-card-actions',[_c('v-row',{attrs:{"dense":""}},[(
          (!_vm.contract.changeOptions || _vm.contract.changeOptions.length < 3) &&
            _vm.isAdminOrCentralizeUser &&
            !_vm.contract.selectedChangeOption &&
            _vm.contract.contractState !== 'UPLOADED' &&
            _vm.contract.contractState !== 'CHANGE_OPTIONS_ADDED' &&
            _vm.contract.contractState !== 'TRANSITIONED_CONTRACT'
        )?_c('v-col',{attrs:{"align":"end"}},[_c('contract-change-option-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var show = ref.show;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return show(null, _vm.contract)}}},[_vm._v(" "+_vm._s(_vm.$t("addChangeOption"))+" ")])]}}],null,false,963080225)})],1):_vm._e(),(
          _vm.contract.changeOptions &&
            _vm.contract.changeOptions.length > 0 &&
            _vm.isAdminOrCentralizeUser &&
            !_vm.contract.selectedChangeOption &&
            _vm.contract.contractState !== 'CHANGE_OPTION_SELECTED' &&
            _vm.contract.contractState !== 'CHANGE_OPTIONS_ADDED' &&
            _vm.contract.contractState !== 'CONTRACT_CHANGE_IN_PROGRESS'
        )?_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.submittingChangeOptions},on:{"click":_vm.submitChangeOptions}},[_vm._v(" "+_vm._s(_vm.$t("submitChangeOptions"))+" ")])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }