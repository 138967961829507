//@ts-ignore

import { Service } from "axios-middleware";
import AuthMiddleware from "./AuthMiddleware";
import Axios from "axios";
const service = new Service(Axios);

service.register(new AuthMiddleware());

export const contractResource = Axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/v1/contracts`,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

export const addressResource = Axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/v1/address`,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

export const providerResource = Axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/v1/providers`,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

export const usersResource = Axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/v1/auth/users`,
  headers: { "Content-Type": "application/json;charset=utf-8" },
});
