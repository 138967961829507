<template>
  <v-container>
    <snackbar></snackbar>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card class="elevation-4">
          <v-card-text>
            <v-window v-model="window">
              <v-window-item>
                <reset-password-step @next="window++" />
              </v-window-item>
              <v-window-item>
                <change-password-step />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    window: null,
  }),

  components: {
    ChangePasswordStep: () => import("@/components/ResetPassword/ChangePasswordStep"),
    ResetPasswordStep: () => import("@/components/ResetPassword/ResetPasswordStep"),
    Snackbar: () => import("@/components/Snackbar"),
  },
};
</script>

<style lang="scss">
.v-chip__content {
  cursor: pointer !important;
}
</style>
