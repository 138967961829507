














































































































import Vue from "vue";

import { providers, contracts } from "@/store";
import { Actions, Getters, Provider } from "@/store/modules/provider.types";
import { mapGetters, mapState } from "vuex";
import { Actions as ContractActions, Getters as ContractGetters, Contract } from "@/store/modules/contract.types";

export default Vue.extend({
  data: () => ({
    name: "",
    website: "",
    icon: "",
    loading: true,
    id: "",
    creating: false,
    dialog: false,
    editing: false,
    files: [] as any[],
    agbFiles: [] as any[],
    cancellationFiles: [] as any[],
    dsgFiles: [] as any[],
    sepaFiles: [] as any[],
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Website",
        value: "website",
      },
      {
        text: "Icon",
        value: "icon",
      },
      {
        text: "Dokumente",
        value: "documents",
        width: "250px",
      },
      {
        value: "actions",
        sortable: false,
      },
    ],
  }),
  methods: {
    ...providers.mapActions([Actions.createProvider, Actions.delete, Actions.edit]),

    async loadData() {
      this.loading = false;
    },

    async submit() {
      this.creating = true;
      if (this.editing) {
        this.edit({
          ID: this.id,
          name: this.name,
          website: this.website,
          file: this.files[0],
          agbFile: this.agbFiles[0],
          cancellationFile: this.cancellationFiles[0],
          dsgFile: this.dsgFiles[0],
          sepaFile: this.sepaFiles[0],
        });
      } else {
        await this.createProvider({
          name: this.name,
          website: this.website,
          file: this.files[0],
          agbFile: this.agbFiles[0],
          cancellationFile: this.cancellationFiles[0],
          dsgFile: this.dsgFiles[0],
          sepaFile: this.sepaFiles[0],
        });
      }
      this.creating = false;
      setTimeout(() => (this.dialog = false), 500);
    },

    async deleteProvider(provider: Provider) {
      this.delete(provider);
    },

    async editProvider(provider: Provider) {
      this.editing = true;
      this.name = provider.name;
      this.website = provider.website;
      this.dialog = true;
      this.id = provider.ID!;
      this.icon = provider.icon;
      this.files = [];
    },

    isReferenced(provider: Provider) {
      const contracts = this.contracts as Contract[];
      return contracts.some((c: Contract) => c.provider === provider.ID);
    },
  },
  computed: {
    ...mapState(["isReady", "search"]),
    ...providers.mapGetters([Getters.providers]),
    ...contracts.mapGetters([ContractGetters.contracts]),
  },
});
