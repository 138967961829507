import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import Archive from "../views/Archive.vue";
import Home from "../views/Home.vue";
import Signin from "../views/Signin.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Signup from "../views/Signup.vue";
import SignupResult from "../views/SignupResult.vue";
import Provider from "../views/Provider.vue";
import Userprofile from "../views/UserProfile.vue";
import CreateContract from "../views/CreateContract.vue";
import ContractDetail from "../views/ContractDetail.vue";
import Contact from "../views/Contact.vue";
import FAQ from "../views/FAQ.vue";
import Agb from "../views/Agb.vue";
import Dsgvo from "../views/Dsgvo.vue";
import Imprint from "../views/Imprint.vue";
import store from "../store";
Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      hasSearch: true,
    },
  },
  {
    path: "/contracts/create",
    name: "contract-create",
    component: CreateContract,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contracts/:id",
    name: "contract-detail",
    component: ContractDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contracts/:id/:tab",
    name: "contract-detail-tab",
    component: ContractDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/archive",
    name: "contract-archive",
    component: Archive,
    meta: {
      requiresAuth: true,
      hasSearch: true,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "how-does-this-work",
    component: FAQ,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/imprint",
    name: "imprint",
    component: Imprint,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/agb",
    name: "agb",
    component: Agb,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dsgvo",
    name: "dsg",
    component: Dsgvo,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth/signin",
    name: "signin",
    component: Signin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth/signup",
    name: "signup",
    component: Signup,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth/signup/:result",
    component: SignupResult,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/admin/providers",
    component: Provider,
    meta: {
      requiresAuth: true,
      hasSearch: true,
    },
  },
  {
    path: "/user/profile",
    name: "profile",
    component: Userprofile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  routes: routes,
});

router.beforeEach(async (to: Route, from: Route, next) => {
  if (!store.getters["isReady"]) {
    try {
      await store.dispatch("cognito/fetchSession");
    } catch (err) {
    } finally {
      store.commit("setIsReady", true);
    }
  }
  const loggedIn = store.getters["cognito/isLoggedIn"];
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({
      path: "/auth/signin",
    });
  } else {
    next();
  }
});

export default router;
