var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","primary":""}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("addAddress")))]),_c('v-card-text',[_c('v-row',{staticClass:"mt-n4"},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('street'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('street') + ' *',"outlined":"","error-messages":errors},model:{value:(_vm.address.street),callback:function ($$v) {_vm.$set(_vm.address, "street", $$v)},expression:"address.street"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('streetNumber'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('streetNumber') + ' *',"outlined":"","error-messages":errors},model:{value:(_vm.address.streetNumber),callback:function ($$v) {_vm.$set(_vm.address, "streetNumber", $$v)},expression:"address.streetNumber"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('zip'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('zip') + ' *',"outlined":"","error-messages":errors},model:{value:(_vm.address.zip),callback:function ($$v) {_vm.$set(_vm.address, "zip", $$v)},expression:"address.zip"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('city') + ' *',"outlined":"","error-messages":errors},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('stairs'),"outlined":""},model:{value:(_vm.address.stairs),callback:function ($$v) {_vm.$set(_vm.address, "stairs", $$v)},expression:"address.stairs"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('floor'),"outlined":""},model:{value:(_vm.address.floor),callback:function ($$v) {_vm.$set(_vm.address, "floor", $$v)},expression:"address.floor"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('doorNumber'),"outlined":""},model:{value:(_vm.address.doorNumber),callback:function ($$v) {_vm.$set(_vm.address, "doorNumber", $$v)},expression:"address.doorNumber"}})],1)],1),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('country') + ' *',"outlined":"","error-messages":errors},model:{value:(_vm.address.country),callback:function ($$v) {_vm.$set(_vm.address, "country", $$v)},expression:"address.country"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("defaultAddress"))+": "),_c('br'),_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.$t("defaultAddressSettings"))+" ")],1)],1),_c('v-row',{staticClass:"mt-n4 mx-1 mb-n4"},[_c('v-switch',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t("shippingAddress")))])]},proxy:true}]),model:{value:(_vm.address.primaryShippingAddress),callback:function ($$v) {_vm.$set(_vm.address, "primaryShippingAddress", $$v)},expression:"address.primaryShippingAddress"}}),_c('v-switch',{staticClass:"ml-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t("billingAddress")))])]},proxy:true}]),model:{value:(_vm.address.primaryBillingAddress),callback:function ($$v) {_vm.$set(_vm.address, "primaryBillingAddress", $$v)},expression:"address.primaryBillingAddress"}})],1)],1),_c('v-card-actions',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("submit")))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }