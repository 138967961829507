





















import Vue from "vue";
import Component from "vue-class-component";

import ContractData from "./ContractData.vue";
import ContractChangeOptionsData from "./ContractChangeOptionsData.vue";
import UserInfo from "@/components/base/UserInfo.vue";
import { ContractAware } from "../../mixins/ContractAware";

@Component({
  components: {
    ContractData,
    ContractChangeOptionsData,
    UserInfo,
  },
})
export default class ContractChangeOverview extends ContractAware {}
