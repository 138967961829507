



































































































import Vue from "vue";
import Component from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";
import UserInfo from "@/components/base/UserInfo.vue";
import { ContractAware } from "../../mixins/ContractAware";
import { addressesNamespace, cognitoNamespace, providersNamesapce } from "../../store";
import { Getters, Provider, ProviderDocumentKey } from "../../store/modules/provider.types";
import { ContractChangeOption } from "../../store/modules/contract.types";
import { Getters as CognitoGetters } from "@/store/modules/cognito/cognito.types";
@Component({ components: { UserInfo } })
export default class AgreeContractChangeDialog extends ContractAware {
  @providersNamesapce.Getter(Getters.providerById) providerById!: (uid: string) => Provider;
  @Prop({ type: Object as () => ContractChangeOption }) selectedChangeOption!: ContractChangeOption;
  @cognitoNamespace.Getter(CognitoGetters.userProfileValidForContractChange)
  userProfileValidForContractChange!: boolean;

  accepted = false;
  @Inject("agree") readonly agree!: any;
  @Inject("cancel") readonly cancel!: any;

  documentByKey(providerId: string, key: ProviderDocumentKey) {
    const provider = this.providerById(providerId);
    return provider.documents?.find((doc) => doc.key == key);
  }
}
