




































































import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default Vue.extend({
  components: {
    Snackbar: () => import("@/components/Snackbar.vue"),
  },
  data() {
    return { email: null, loading: false };
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions("cognito", ["resendSignUp"]),
    async send() {
      this.loading = true;
      try {
        await this.resendSignUp({ username: this.email });
        this.setSnackbar({
          type: "success",
          msg: this.$t("registerSuccess"),
        });
        setTimeout(() => {
          this.$router.push("/");
        }, 10000);
      } catch (err) {
        this.setSnackbar({
          type: "error",
          msg: this.$t(err.code),
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
