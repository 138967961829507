














































import Vue from "vue";
import Component from "vue-class-component";
import ContractData from "@/components/ContractDetail/ContractData.vue";
import ContractPreview from "@/components/ContractDetail/ContractPreview.vue";
import ContractStatus from "@/components/ContractDetail/ContractStatus.vue";
import ContractChangeOptions from "@/components/ContractDetail/ContractChangeOptions.vue";
import ContractChangeOverview from "@/components/ContractDetail/ContractChangeOverview.vue";
import { cognitoNamespace, contractsNamespace } from "@/store";
import { Getters as CognitoGetters } from "@/store/modules/cognito/cognito.types";
import { Actions, ContractState, Mutations } from "@/store/modules/contract.types";
import { ActionMethod, MutationMethod } from "vuex";
import { Watch } from "vue-property-decorator";
import { Route } from "aws-sdk/clients/apigatewayv2";
import VComponent from "../components/base/VComponent.vue";
import { ContractAware } from "@/mixins/ContractAware";

@Component({
  components: {
    ContractData,
    ContractPreview,
    ContractStatus,
    ContractChangeOverview,
    ContractChangeOptions,
    VComponent,
  },
})
export default class ContractDetail extends ContractAware {
  get tabs() {
    return [
      { text: this.$t("contract"), icon: "mdi-file-find", to: "vertrag", component: "contract-preview" },
      { text: this.$t("details"), icon: "mdi-information", to: "details", component: "contract-data" },
      { text: this.$t("status"), icon: "mdi-history", to: "status", component: "contract-status" },
      {
        text: this.$t("changeOptions"),
        icon: "mdi-message-alert",
        to: "wechseloptionen",
        hideOnContractStates: [
          ContractState.UPLOADED,
          ContractState.CONTRACT_DATA_ADDED,
          ContractState.CONTRACT_CHANGE_FAILED,
          ContractState.CONTRACT_CHANGED,
          ContractState.TRANSITIONED_CONTRACT,
          ContractState.ARCHIVED,
        ],
        component: "contract-change-options",
      },
      {
        text: this.$t("changeView"),
        icon: "mdi-eye-outline",
        to: "admin-change",
        component: "contract-change-overview",
        requiresAdminOrCentralize: true,
      },
    ].filter((elem) => {
      if (elem.requiresAdminOrCentralize) return this.isAdminOrCentralizeUser;
      else if (elem.hideOnContractStates)
        return !elem.hideOnContractStates.includes(this.contract!.contractState!) || this.isAdminOrCentralizeUser;
      return true;
    });
  }

  async mounted() {
    this.setEditContractId(this.$route.params.id);

    if (this.isAdminOrCentralizeUser) {
      await this.lockContract(this.contract);
    }
  }

  @Watch("$route.params.id", { immediate: true, deep: true })
  async contractIdChanged(newVal: Route) {
    this.setEditContractId(this.$route.params.id);
  }

  @contractsNamespace.Mutation(Mutations.setEditContractId)
  setEditContractId!: MutationMethod;

  @contractsNamespace.Action(Actions.lock)
  lockContract!: ActionMethod;

  @cognitoNamespace.Getter(CognitoGetters.isAdminOrCentralizeUser)
  isAdminOrCentralizeUser!: boolean;
}
