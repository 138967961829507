import Amplify from "@aws-amplify/core";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID as string,
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID as string,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEBCLIENT_ID as string,
    region: process.env.VUE_APP_COGNITO_USER_POOL_REGION as string,
  },
});
export const cognitoModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
