













































































































import Vue from "vue";
import Component from "vue-class-component";
import { Address } from "../../store/modules/address.types";

@Component({})
export default class BaseAddressDialog extends Vue {
  private address: Address = {};
  private dialog: boolean = false;

  edit(address: Address) {
    this.address = { ...address };
    this.dialog = true;
  }

  submit() {
    if (this.address.ID) {
      this.$emit("update", this.address);
    } else {
      this.$emit("create", this.address);
    }
    this.dialog = false;
  }
}
