











































































































































































import moment from "moment";
import Vue from "vue";
import { mapActions, mapState, mapMutations, ActionMethod, MutationMethod } from "vuex";
import { contracts, providers, cognito, cognitoNamespace, contractsNamespace, providersNamesapce } from "@/store";
import { Actions, Getters, Contract, ContractChangeOption, ContractState } from "@/store/modules/contract.types";
import { Getters as ProviderGetters, Provider, Actions as ProviderActions } from "@/store/modules/provider.types";
import { Getters as CognitoGetters, Actions as CognitoActions } from "@/store/modules/cognito/cognito.types";
import { Actions as RootActions, Mutations as RootMutiations } from "@/store";
import Snackbar from "@/components/Snackbar.vue";
import { usersResource } from "../../api";
import Component from "vue-class-component";
import { Action, Mutation, State } from "vuex-class";

const ContractDashboardProps = Vue.extend({
  props: {
    archive: {
      type: [Boolean, String],
      default: false,
    },
  },
  components: {
    Snackbar,
  },
});

@Component({})
export default class ContractDashboard extends ContractDashboardProps {
  @contractsNamespace.Action(Actions.update) update!: ActionMethod;
  @contractsNamespace.Action(Actions.setContractState) setContractState!: ActionMethod;

  @Mutation(RootMutiations.setSnackbar) setSnackbar!: MutationMethod;
  @State("search") search!: string;

  @cognitoNamespace.Getter(CognitoGetters.userForId) userForId!: (uid: string) => any;
  @cognitoNamespace.Getter(CognitoGetters.isAdminOrCentralizeUser) isAdminOrCentralizeUser!: boolean;
  @cognitoNamespace.Getter(CognitoGetters.userAttributeForId) userAttributeForId!: (uid: string) => any;
  @cognitoNamespace.Getter(CognitoGetters.userId) userId!: string;

  @providersNamesapce.Getter(ProviderGetters.providers) providers!: Provider[];
  @providersNamesapce.Getter(ProviderGetters.providerById) providerById!: (uid: string) => Provider;

  @contractsNamespace.Getter(Getters.contracts) contracts!: Contract[];

  @contractsNamespace.Getter(Getters.archivedContracts) archivedContracts!: Contract[];

  @contractsNamespace.Getter(Getters.overallCost) overallCost!: number;
  @contractsNamespace.Getter(Getters.savings) savings!: number;

  tab = null;
  creating = false;
  users = [];
  loading = true;
  provider = null;
  files = [];
  archivingId = "";
  noContractsDialog = false;

  loadData() {
    this.loading = false;
    if (this.contracts.length === 0 && !this.archive) {
      this.noContractsDialog = true;
    }
  }

  async updateChangeAutomatically(contract: Contract, value: boolean) {
    contract.changeAutomatically = value;
    await this.update({
      contract: contract,
    });

    if (value) {
      console.log("confirm dialog");
      await this.$confirm(this.$t("changeAutomaticallyActivated") as string, {
        buttonFalseText: "",
        icon: "mdi-information-outline",
        title: this.$t("attentionLabel") as string,
        buttonTrueText: this.$t("understandLabel") as string,
      });
    } else {
      this.setSnackbar({
        type: "success",
        msg: this.$t(`changeAutomatically_${value}`),
      });
    }
  }

  async archiveContract(contract: Contract) {
    this.archivingId = contract.ID!;
    await this.setContractState({
      contract: contract,
      state: ContractState.ARCHIVED,
    });

    this.archivingId = "";
    this.setSnackbar({
      type: "success",
      msg: this.$t("archivedSuccess"),
    });
  }

  contractProgress(contract: Contract) {
    const contractStart = moment(contract.startDate);
    const contractDuration = moment.duration(moment(contract.endDate).diff(contractStart)).as("days");
    const alreadyElapsed = moment.duration(moment().diff(contractStart)).as("days");
    return (alreadyElapsed / contractDuration) * 100;
  }

  contractInfo(contract: Contract) {}

  customSearch(value: any, search: string, item: Contract) {
    return (
      (value != null &&
        search != null &&
        value
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1) ||
      (item.provider !== null &&
        (this.providerById(item.provider!) as Provider)?.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
      this.userForId(item.UID!)
        ?.email?.toLowerCase()
        .includes(search.toLowerCase())
    );
  }

  clickRow(item: Contract) {
    this.$router.push(`/contracts/${item.ID}`);
  }

  canArchiveContract(contract: Contract) {
    return (
      contract.contractState === ContractState.CONTRACT_CHANGED ||
      contract.contractState === ContractState.CONTRACT_CHANGE_FAILED ||
      moment(contract.endDate).isSameOrBefore(moment())
    );
  }

  get headers() {
    if (this.isAdminOrCentralizeUser) {
      return [
        {
          text: this.$t("contractState"),
          value: "contractState",
        },
        {
          text: this.$t("provider"),
          value: "provider",
        },
        {
          text: this.$t("contractType"),
          value: "contractType",
        },
        {
          text: this.$t("totalPricePerYear"),
          value: "totalPricePerYear",
        },
        {
          text: this.$t("startDate"),
          value: "startDate",
          sortable: true,
          sort: (a: string, b: string) => moment(a).diff(moment(b)),
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
        },
        {
          text: this.$t("changeAutomatically"),
          value: "changeAutomatically",
        },
        {
          text: this.$t("UID"),
          value: "UID",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    } else {
      return [
        {
          text: this.$t("contractState"),
          value: "contractState",
        },
        {
          text: this.$t("provider"),
          value: "provider",
        },
        {
          text: this.$t("contractType"),
          value: "contractType",
        },
        {
          text: this.$t("totalPricePerYear"),
          value: "totalPricePerYear",
        },
        {
          text: this.$t("startDate"),
          value: "startDate",
          sortable: true,
          sort: (a: string, b: string) => moment(a).diff(moment(b)),
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
        },
        {
          text: this.$t("changeAutomatically"),
          value: "changeAutomatically",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    }
  }

  get contractList() {
    if (this.archive) {
      return this.archivedContracts;
    }
    return this.contracts;
  }
}
