<template>
  <v-container>
    <v-row id="login" justify="center" style="width: 100%">
      <v-card class="elevation-4" style="width: 100%">
        <v-card-title>
          <base-subheading>{{ $t("dsg") }}</base-subheading>
        </v-card-title>
        <v-card-text>
          <p>
            Stand: [03.04.2021]
          </p>
          <h3>
            1. Allgemeine Informationen zur centralize GmbH
          </h3>
          <p>
            Die centralize GmbH, Untere Landstraße 37, 3500 Krems an der Donau, Österreich betreibt unter dem Namen
            “centralize“ das Vertrags- und Dokumentenverwaltungssystem und die Website www.centralize.at. Der
            datenschutzrechtliche Auftraggeber bzw Verantwortliche bei der Verarbeitung Ihrer personenbezogenen Daten
            ist daher die centralize GmbH. Für die centralize GmbH hat die Geheimhaltung und der Schutz von
            personenbezogenen Daten einen hohen Stellenwert. Personenbezogene Daten werden stets unter Einhaltung der
            datenschutzrechtlichen Bestimmungen, insbesondere des österreichischen Datenschutzgesetzes ("DSG") und der
            EU Datenschutzgrundverordnung ("DSGVO") verarbeitet.
          </p>
          <h3>
            2. Erhebung von Daten und Zwecke der Datenverarbeitung
          </h3>
          <p>
            Die centralize GmbH erhebt und verarbeitet auf Basis einer Einwilligung folgende personenbezogene Daten von
            Nutzern beim <strong>Aufruf der Website</strong> centralize.at: IP-Adresse, User-ID, Klick-Verhalten,
            Referral-URLs, verwendeter Browser, Betriebssystem.
          </p>
          <p>
            Diese Daten aus den Aufrufen und dem Userverhalten auf der Website centralize.at werden zum Zweck der
            Qualitätssicherung und Verbesserung unserer Services erhoben und analysiert.
          </p>
          <p>
            Zusätzlich wird der Kunde im Laufe des <strong>Anmeldeprozess </strong>
            aufgefordert, personenbezogene Daten und sonstige Informationen bekanntzugeben, sei es durch manuelle
            Eingabe oder durch Hochladen von relevanten Dokumenten (wie etwa Energieverträge, Energierechnungen,
            Netzrechnungen, Netzverträge). Insbesondere verarbeitet die centralize GmbH folgende personenbezogene Daten
            über ihre Kunden:
          </p>
          <p>
            Vorname, Nachname, Geburtsdatum, Adresse inklusive aller Zusätze, Telefonnummer, E-Mailadresse,
            Führerscheinnummer oder Personalausweisnummer oder Reisepassnummer und alle Daten zur Ermittlung des für den
            Kunden günstigsten Energietarifs (Angabe des aktuellen Energieanbieters, des aktuellen Energietarifs,
            etwaiger Vertragsbindungen bzw. Hochladen einer Energierechnung, gewünschte Energieart, Stromzählertyp,
            Zählpunktbezeichnung, Verbrauch, ausgewählten Produktpräferenzen).
          </p>
          <p>
            Diese Kundendaten werden von der centralize GmbH verschlüsselt gespeichert und für Zwecke der
            Vertragserfüllung mit dem Kunden verarbeitet: (wiederkehrende) Vertragsabschlüsse mit Energielieferanten,
            Vertragskündigungen mit Energielieferanten, Übermittlung von SEPA-Einzugsermächtigungen, Statistische
            Analysen, Wiederverwendung der Anmeldedaten für künftige Wechselprozesse, Ausfüllen von
            Online-Wechsel-Formularen für den Abschluss von Online-Wechselvorgängen, Erstellung eines
            Online-Benutzer-Kontos beim Energieanbieter im Namen des Kunden, Kontaktierung des Kunden (soweit dies zur
            Vertragserfüllung erforderlich ist), Unterstützung des Kunden bei Fragen, Informationsübermittlung (wie zum
            Beispiel Änderungen der AGBs). Die centralize GmbH verarbeitet die Daten der Kunden solange der Vertrag in
            Kraft ist und darüber hinaus lediglich solange gesetzliche Aufbewahrungspflichten bestehen.
          </p>
          <p>
            Weiters werden – soweit diesbezüglich eine Zustimmung des Kunden vorliegt – die bekanntgegebenen,
            personenbezogenen Daten des Kunden durch centralize und/oder der folgenden hierfür von centralize
            möglicherweise beauftragten Partner: [etwa wie Marktforschungsunternehmen], zum Zweck der Kontaktaufnahme
            (soweit nicht zur Vertragsabwicklung erforderlich), Kundenprofilerstellung, Qualitätssicherung, der
            Zusendung von Fragebogen, der Zusendung von Information über Sonderangebote/Werbeaktionen, Erweiterungen des
            Serviceangebotes, Information über neue oder ähnliche Produkte sowie Spar-Tipps, via Telefon und/oder Email
            verwendet. Diese Zustimmung kann vom Kunden jederzeit und kostenlos widerrufen werden.
          </p>
          <p>
            Um die entsprechenden Strom-/Gastarife von verschiedenen Anbietern vergleichen und für den Kunden aussuchen
            zu können, werden folgende Daten des Kunden bei der Benützung des compera<strong> Tarifkalkulators</strong>
            verarbeitet: Postleitzahl, Jahresverbrauch, Energieanbieter, Tarif bei dem jeweiligen Energieanbieter,
            Netzbetreiber, Präferenzen zu Produktauswahl.
          </p>
          <p>
            Nach der Auswahl eines passenden Strom-/Gastarifes durch den Kunden werden personenbezogene und sonstige
            Daten des Kunden an den gewählten Energieanbieter weitergeleitet, um den Tarifwechsel einzuleiten. Um einen
            erfolgreichen Wechsel zu einem neuen Energieanbieter zu gewährleisten, werden folgende Daten an den
            Energieanbieter weitergeleitet: Name, Geburtsdatum, Rechnungsanschrift inklusive aller Zusätze,
            Lieferanschrift inklusive aller Zusätze, Kontaktinformationen, Ausweisdaten, Energieverbrauchsdaten, Daten
            zum Zähler des Kunden und bisherigen Netzbetreiber, bisherigen Energieanbieter und Energietarif, sowie
            nötige Daten zur Durchführung des SEPA Lastschriftverfahrens.
          </p>
          <p>
            <strong>Weitere Verwendung von personenbezogenen Informationen</strong>
          </p>
          <p>
            Zusammengefasst verarbeitet die centralize GmbH personenbezogene Daten ihrer Kunden und Nutzer der Website
            centralize.at zu folgenden Zwecken:
          </p>
          <ul type="disc">
            <li>
              für statistische Datenanalysen;
            </li>
            <li>
              für die Entwicklung neuer Serviceleistungen;
            </li>
            <li>
              für die Feststellung von Trends bei der Nutzung;
            </li>
            <li>
              um Sicherheitsbedrohungen, Betrug und andere böswillige Aktivitäten zu verhindern und festzustellen;
            </li>
            <li>
              um unseren gesetzlichen Verpflichtungen nachzukommen und unsere Vereinbarungen durchzusetzen;
            </li>
            <li>
              zu Absicherung des Online-Zuganges;
            </li>
            <li>
              zur Erstellung von Abrechnungen und Statistiken über das Zugriffsverhalten (z.B. Anzahl der Zugriffe auf
              eine Quelle, Anzahl der Zugriffe des Kunden insgesamt und pro Quelle, Änderungen in der Zugriffsfrequenz).
            </li>
          </ul>
          <h3>
            3. Cookies
          </h3>
          <p>
            Auf der Website centralize.at werden personenbezogene Daten gesammelt und gespeichert. Hierzu werden Cookies
            eingesetzt. Bei Cookies handelt es sich um kleine Textdateien, die eine Website oder ihr Service-Provider
            über den Webbrowser auf der Festplatte des zugreifenden Geräts platziert, damit die Website komfortabler
            gestaltet und das Nutzerverhalten des Besuchers aufgrund seiner Aktivitäten auf der Website besser
            verstanden werden kann.
          </p>
          <p>
            Sofern der Besucher der Website centralize.at seine Einwilligung zur Cookie-Setzung gegeben hat, werden
            folgende Daten über Cookies erhoben und verarbeitet: Informationen über die besuchten Websites
            (Userverhalten), verwendeter Browser, IP Adresse, Klick-Verhalten, Referral-URLs, verwendeter Browser,
            Betriebssystem. Wenn Sie Cookies nicht akzeptieren möchten, ändern Sie bitte Ihre Browsereinstellung
            entsprechend. Beachten Sie, dass dadurch Funktionalitäten der Website eingeschränkt werden können.
          </p>
          <h3>
            4. Google Analytics
          </h3>
          <p>
            Die Website centralize.at benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“).
            Google Analytics verwendet Cookies, um eine Analyse der Ihrer Nutzung der Website zu ermöglichen.
          </p>
          <p>
            Die folgenden Informationen werden von Google Analytics gesammelt: Browser, Gerätetyp, Gerätemodell,
            Standort, Land, Stadt, Service-Provider, Bildschirmauflösung, Verweildauer, Sprache, Betriebssystem,
            besuchte Seiten auf der Website.
          </p>
          <p>
            Die durch den Google Analytics Cookie erzeugten Informationen über die Nutzung der Website werden in der
            Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Auftrag der centralize GmbH
            wird Google diese Informationen benutzen, um die Nutzung der Website durch die Besucher auszuwerten, um
            Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber der centralize GmbH zu erbringen. Die im Rahmen von
            Google Analytics erhobene IP-Adresse des Besuchers der Website wird nicht mit anderen Daten von Google
            zusammengeführt.
          </p>
          <p>
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen der Website vollumfänglich werden nutzen können.
          </p>
          <h3>
            5. Facebook
          </h3>
          <p>
            Wir nutzen einen “Pixel” des sozialen Netzwerks Facebook Inc. (“Facebook”). Durch den Facebook-Pixel kann
            das Verhalten von Nutzern nach einem Klick auf eine Facebook-Werbeanzeige verfolgt werden. Mithilfe des
            Facebook-Pixels können wir nachvollziehen, wie unsere Werbemaßnahmen auf Facebook angenommen werden und
            Optimierungsmaßnahmen ableiten. Die Nachverfolgung von Nutzern, die nach einem Klick auf eine unserer
            Facebook-Werbeanzeige auf unsere Website gelangen, kann bis zu 180 Tage dauern. Falls du die
            Cookie-Speicherung für Facebook deaktivieren möchten, musst du deine Browsereinstellungen ändern. Die
            erhobenen Daten bleiben anonym und bieten keine Rückschlüsse auf die Identität der Nutzer. Facebook
            speichert und verarbeitet die Daten für eigene Werbezwecke entsprechend der Facebook-Datenrichtlinie
            <a href="https://www.facebook.com/about/privacy/">
              https://www.facebook.com/about/privacy/
            </a>
            .
          </p>
          <h3>
            6. LinkedIn
          </h3>
          <p>
            Wir verwenden auf unserer Webseite die Conversion-Tracking-Technologie sowie die Retargeting-Funktion von
            LinkedIn. Mithilfe dieser Technologie können Besuchern dieser Webseite personalisierte Werbeanzeigen auf
            LinkedIn ausgespielt werden. Des Weiteren entsteht die Möglichkeit, anonyme Berichte zur Performance der
            Werbeanzeigen sowie Informationen zur Webseiteninteraktion zu erstellen. Hierzu ist das LinkedIn Insight-Tag
            auf dieser Webseite eingebunden, wodurch eine Verbindung zum LinkedIn-Server hergestellt wird, sofern du
            diese Webseite besuchst und parallel in deinem LinkedIn-Account eingeloggt bist. In der
            Datenschutzrichtlinie von LinkedIn unter
            <a href="https://www.linkedin.com/legal/privacy-policy">
              https://www.linkedin.com/legal/privacy-policy
            </a>
            findest du weitere Informationen zur Datenerhebung und Datennutzung sowie die Möglichkeiten und Rechte zum
            Schutz deiner Privatsphäre. Wenn du bei LinkedIn eingeloggt bist, kannst du die Datenerhebung jederzeit
            unter folgendem Link deaktivieren:
            <a href="https://www.linkedin.com/psettings/enhanced-advertising">
              https://www.linkedin.com/psettings/enhanced-advertising
            </a>
            .
          </p>
          <h3>
            7. Betroffenenrechte
          </h3>
          <p>
            Sie haben ein Recht auf Auskunft über die von uns über Sie verarbeiteten personenbezogenen Daten. Zusätzlich
            haben Sie das Recht auf Berichtigung unrichtiger Daten und Löschung. Sie haben außerdem ein Recht auf
            Einschränkung der Verarbeitung, ein Widerspruchsrecht sowie das Recht auf Datenübertragbarkeit. Außerdem
            haben Sie ein Beschwerderecht an die Datenschutzbehörde.
          </p>
          <p>
            Bei diesbezüglichen oder sonstigen Fragen zum Thema Datenschutz wenden Sie sich bitte an
            <a href="mailto:office@centralize.at">office@centralize.at</a>.
          </p>
          <h3>
            8. Datensicherheit
          </h3>
          <p>
            <strong>Implementierte Sicherheitsmaßnahmen</strong>
          </p>
          <p>
            Die centralize GmbH hat branchenübliche Datensicherheitsmaßnahmen implementiert, um die Vertraulichkeit und
            Integrität personenbezogener Daten zu gewährleisten.
          </p>
          <h3>
            9. Sonstiges
          </h3>
          <p>
            Im Fall einer Änderung dieser Datenschutzerklärung wird die centralize GmbH die geänderte Version mit einem
            aktualisierten Versionsdatum hier veröffentlichen.
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
