































































































import Vue from "vue";
import Component from "vue-class-component";

import { contractsNamespace } from "@/store";
import { Actions, Contract, ContractType, Getters } from "@/store/modules/contract.types";
import { ActionMethod, MutationMethod } from "vuex";
import { Mutation } from "vuex-class";

import Snackbar from "@/components/Snackbar.vue";

@Component({
  components: {
    Snackbar,
  },
})
export default class CreateContract extends Vue {
  @contractsNamespace.Action(Actions.createContract)
  createContract!: ActionMethod;
  @contractsNamespace.Action(Actions.update)
  update!: ActionMethod;
  @contractsNamespace.Getter(Getters.contractById)
  contractById!: (id: string) => Contract;
  @contractsNamespace.State("editContractId")
  editContractId!: string;
  @Mutation
  setSnackbar!: MutationMethod;

  public creating: boolean = false;
  public files: File[] = [];
  private dialog: boolean = false;
  private tab: number = 0;
  private contractType: ContractType | null = null;
  private changeAutomatically: boolean = false;

  show() {
    this.files = [];
  }

  async nextClicked() {
    if (this.tab == 1) {
      await this.submit();
      setTimeout(async () => {
        await this.$router.push("/");
      }, 3000);

      return;
    }
    this.tab += 1;
  }

  async submit() {
    this.creating = true;
    try {
      await this.createContract({
        files: this.files,
        changeAutomatically: this.changeAutomatically,
        contractType: this.contractType,
      });

      this.setSnackbar({
        type: "success",
        msg: this.$t("createContractSuccess"),
      });
    } catch (err) {
      this.setSnackbar({
        type: "error",
        msg: this.$t("resultErrorHeadline"),
      });
    }
    this.creating = false;
  }

  get contractTypes() {
    return Object.values(ContractType).map((contractType) => {
      return {
        id: contractType,
        text: this.$t(`${contractType}`),
      };
    });
  }

  get tabs() {
    return [this.$t("upload"), this.$t("confirm")];
  }

  get availableSteps() {
    if (this.tab == 0) {
      return [0, 1];
    }
    if (this.tab == 1) {
      return [2];
    }
  }
}
