import { GetterTree } from "vuex";
import { SalutationList, Getters, UserAttributes } from "@/store/modules/cognito/cognito.types";
import { Getter } from "vuex-class";
import state from "./state";

export default {
  // TODO: ensure best method to verify this
  [Getters.isLoggedIn]: (store = {}) => {
    const session = store.session;
    if (!session) return false;
    const accessToken = session.accessToken;
    if (!accessToken) return false;
    const hasToken = accessToken.jwtToken;
    const isActive = new Date(accessToken.payload.exp * 1000) > new Date();
    const isMe = accessToken.payload.username === store.user.username;
    return hasToken && isActive && isMe;
  },
  session: (store = {}) => ("session" in store && Object.keys(store.session).length !== 0 ? store.session : false),
  [Getters.userId]: (store = {}) => {
    if (store.user && store.user.attributes) {
      return store.user.attributes.sub;
    } else if (store.user && store.user.userSub) {
      return store.user.userSub;
    } else {
      return false;
    }
  },
  username: (store = {}) => (store.user && store.user.user ? store.user.user.username : false),
  //userAttributes: (store = {}) => (store.user && store.user.attributes ? store.user.attributes : false),
  userAttributes: (store = {}) => {
    if (store.userAttributes) {
      return store.userAttributes;
    } else {
      return false;
    }
  },
  usersList: (state) => state.users,
  userForId: (state) => (id: string) => {
    if (state.users) {
      const user = state.users.filter((u: any) => u.ID === id);
      if (user.length > 0) {
        return user[0];
      }
    }
    return {};
  },
  [Getters.isAdmin]: (state, getters) =>
    getters["userGroups"] && getters["userGroups"].find((i: string) => i.includes("admin-user-group")),
  [Getters.isCentralizeUser]: (state, getters) =>
    getters["userGroups"] && getters["userGroups"].find((i: string) => i.includes("centralize-users-user-group")),
  [Getters.isAdminOrCentralizeUser]: (state, getters) => getters[Getters.isAdmin] || getters[Getters.isCentralizeUser],
  [Getters.userProfileValidForContractChange]: (state, getters) => {
    const userAttributes: UserAttributes = getters.userAttributes;

    return (
      Boolean(userAttributes.email) &&
      Boolean(userAttributes.firstName) &&
      Boolean(userAttributes.lastName) &&
      Boolean(userAttributes.phoneNumber) &&
      Boolean(userAttributes.IBAN)
    );
  },
  userGroups: (store = {}) =>
    store.session &&
    store.session.accessToken &&
    store.session.accessToken.payload &&
    store.session.accessToken.payload["cognito:groups"]
      ? store.session.accessToken.payload["cognito:groups"]
      : false,
} as GetterTree<any, any>;
