




























































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { providers, contractsNamespace, cognitoNamespace, providersNamesapce } from "@/store";
import {
  Contract,
  ContractChangeOption,
  Actions,
  ContractState,
  Getters,
  ContractStateTransition,
} from "@/store/modules/contract.types";
import { Getters as ProviderGetters, Provider } from "@/store/modules/provider.types";
import { Getters as CognitoGetters } from "@/store/modules/cognito/cognito.types";
import ContractChangeOptionDialog from "./ContractChangeOptionDialog.vue";
import AgreeContractChangeDialog from "./AgreeContractChangeDialog.vue";
import { ActionMethod } from "vuex";
import { cognito } from "@/store";
import { ContractAware } from "../../mixins/ContractAware";
import { AgentProfile } from "aws-sdk/clients/codeguruprofiler";
import AwaitableDialog from "../base/AwaitableDialog.vue";
import { timeout } from "../../helpers";

@Component({
  components: {
    ContractChangeOptionDialog,
    AgreeContractChangeDialog,
  },
})
export default class ContractChangeOptions extends ContractAware {
  @providersNamesapce.Getter(ProviderGetters.providerById)
  providerById!: (uid: string) => Provider;
  @cognitoNamespace.Getter(CognitoGetters.userForId)
  userForId!: (uid: string) => any;
  @cognitoNamespace.Getter(CognitoGetters.isAdminOrCentralizeUser)
  isAdminOrCentralizeUser!: boolean;
  @cognitoNamespace.Getter(CognitoGetters.userId)
  userId!: boolean;

  private selectingId: string = "";
  private submittingContractChange: boolean = false;
  private failChangeContract: boolean = false;
  private changeProcessContract: boolean = false;
  private submittingChangeOptions: boolean = false;
  selectedChangeOption: ContractChangeOption | null = null;

  async submitChangeOptions() {
    this.submittingChangeOptions = true;
    await this.setContractState({
      contract: this.contract,
      state: ContractState.CHANGE_OPTIONS_ADDED,
    });
    this.submittingChangeOptions = false;
  }

  async selectContractChangeOption(changeOption: ContractChangeOption) {
    // TODO show dialog before selection

    this.selectedChangeOption = changeOption;
    const accepted = await (this.$refs.agreeChangeDialog as AwaitableDialog).open();

    if (accepted) {
      this.selectingId = changeOption.ID!;
      await this.selectChangeOption({
        contract: this.contract,
        changeOption: changeOption,
      });
      this.selectingId = "";
      this.$emit("close", 1000);
    }
  }

  async setContractChangeInProgress() {
    this.changeProcessContract = true;
    await this.setContractState({
      contract: this.contract,
      state: ContractState.CONTRACT_CHANGE_IN_PROGRESS,
    });
    this.changeProcessContract = false;
  }

  async submitContractChange() {
    this.submittingContractChange = true;
    const transition: ContractStateTransition = await this.setContractState({
      contract: this.contract,
      state: ContractState.CONTRACT_CHANGED,
    });

    await timeout(2000);

    this.$router.push(`/contracts/${transition.currentState.ID}/vertrag`);

    this.submittingContractChange = false;
  }

  async submitContractChangeFailed() {
    this.failChangeContract = true;
    await this.setContractState({
      contract: this.contract,
      state: ContractState.CONTRACT_CHANGE_FAILED,
    });
    this.failChangeContract = false;
    this.$emit("close");
  }
  get editable() {
    return this.isAdminOrCentralizeUser;
  }

  get contract() {
    return this.currentContract;
  }
}
