<template>
  <v-app-bar id="app-bar" absolute app flat height="75" color="transparent">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light">
      {{ $t(`${$route.name}`) }}
    </v-toolbar-title>
    <v-spacer />

    <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 365px;"
      v-if="$route.meta.hasSearch"
      :value="search"
      @input="setSearch"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-menu bottom left offset-y origin="top right" transition="scale-transition" v-if="false">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`" :item="n"> </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />

          <app-bar-item v-else :key="`item-${i}`" :item="p">
            <v-list-item-title v-text="p.title" class="primary--text" />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { Actions, cognito, Mutations } from "@/store";
// Components
import { VHover } from "vuetify/lib";
import BaseItem from "@/components/base/Item";
// Utilities
import { mapState, mapMutations, mapActions } from "vuex";
import { Getters, Actions as CognitoActions } from "@/store/modules/cognito/cognito.types";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover, active }) => {
              console.log(active);
              return h(
                BaseItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    notifications: [
      { title: "Mike John Responded to your email" },
      { title: "You have 5 new tasks" },
      { title: "You're now friends with Andrew" },
      { title: "Another Notification" },
      { title: "Another one" },
    ],
  }),

  computed: {
    ...mapState(["drawer", "search"]),
    ...cognito.mapGetters([Getters.userAttributes]),
    profile() {
      return [
        { title: this.$t("profile"), to: "/user/profile" },
        { divider: true },
        {
          title: this.$t("logout"),
          action: async () => {
            await this.signOut();
            this.$router.push("/auth/signin");
          },
        },
      ];
    },
  },

  methods: {
    ...cognito.mapActions([CognitoActions.signOut]),
    ...mapActions([Actions.setDrawer]),
    ...mapMutations([Mutations.setSearch]),
  },
};
</script>
