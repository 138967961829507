







import Vue from "vue";
import Component from "vue-class-component";
import { Address } from "../store/modules/address.types";
import { Contract } from "../store/modules/contract.types";

const AddressSelectProps = Vue.extend({
  props: {
    label: String,
    value: String,
    userId: String,
    disabled: Boolean,
    loading: Boolean,
    contract: {
      type: Object as () => Contract,
    },
    addresses: {
      type: Array as () => Address[],
      default: [],
    },
  },

  computed: {
    inputVal: {
      get(): string {
        return this.value;
      },
      set(val: string) {
        this.$emit("input", val);
      },
    },
  },
});

@Component({})
export default class AddressSelect extends AddressSelectProps {}
