import Vue from "vue";
import { AxiosResponse } from "axios";
import * as AWS from "aws-sdk";
import { ManagedUpload } from "aws-sdk/clients/s3";
import { v4 as uuid } from "uuid";
import { Module } from "vuex";
import { contractResource, usersResource } from "@/api";
import {
  Actions,
  Contract,
  Getters,
  Mutations,
  ContractChangeOption,
  ContractModuleState,
  ContractState,
  ContractType,
  ContractStateTransition,
  ContractStateIndex,
} from "./contract.types";
import { Actions as RootActions } from "..";
import { set, add } from "../helpers";
import { FileSystemCredentials } from "aws-sdk";
import { DocumentDto } from "./documents.dto";

export const contractModule: Module<ContractModuleState, any> = {
  namespaced: true,
  state: { data: [], progress: 0, editContractId: null },
  mutations: {
    setEditContractId: set("editContractId"),
    data: set("data"),
    addContract: add("data"),
    progress: set("progress"),
    update: (state: ContractModuleState, payload: Contract) => {
      const index = state.data.findIndex((ct) => ct.ID === payload.ID);
      if (index != -1) {
        state.data[index] = payload;
        Vue.set(state.data, index, payload);
      }
    },
    delete: (state, contract: Contract) => {
      state.data.splice(
        state.data.findIndex((o) => o.ID === contract.ID),
        1
      );
    },
  },
  actions: {
    async load({ dispatch, commit }) {
      const contracts = (await contractResource.get<any, AxiosResponse<Contract[]>>("")).data;
      await dispatch(RootActions.updateCredentials, null, {
        root: true,
      });
      const s3 = new AWS.S3({});

      commit("data", contracts);
    },

    async [Actions.getDownloadUrl]({ dispatch }, contract: Contract): Promise<string> {
      await dispatch(RootActions.updateCredentials, null, {
        root: true,
      });
      const s3 = new AWS.S3({});
      const url = await s3.getSignedUrlPromise("getObject", {
        Bucket: process.env.VUE_APP_USER_BUCKET,
        Key: contract.s3Path,
        ResponseContentDisposition: `inline; filename=${contract.originalFilename}`,
      });
      return url;
    },

    async [Actions.getDocumentUrl]({ dispatch }, document: DocumentDto): Promise<string> {
      await dispatch(RootActions.updateCredentials, null, {
        root: true,
      });

      const s3 = new AWS.S3({});
      const url = await s3.getSignedUrlPromise("getObject", {
        Bucket: process.env.VUE_APP_USER_BUCKET,
        Key: document.path,
        ResponseContentDisposition: `inline; filename=${document.fileName}`,
      });
      return url;
    },

    async [Actions.uploadContract]({ dispatch, commit }, payload: { contract: Contract; file: File; user: any }) {
      const awsCredentials: AWS.CognitoIdentityCredentials = await dispatch(RootActions.updateCredentials, null, {
        root: true,
      });
      const s3 = new AWS.S3({});
      const fileName = payload.file.name;
      const identityId = payload.user ? payload.user.identityId : awsCredentials.identityId;

      const upload = s3.upload({
        Bucket: process.env.VUE_APP_USER_BUCKET!,
        ContentType: payload.file.type,
        Key: `${process.env.VUE_APP_STAGE}-centralize-user-home/${identityId}/${
          payload.contract.ID
        }/${new Date().getTime()}_${fileName}`,
        Body: payload.file,
      });
      upload.on("httpUploadProgress", (progress) => {
        commit("progress", progress.loaded / progress.total);
      });
      return await upload.promise();
    },

    async [Actions.uploadMultiple]({ dispatch }, payload: { contract: Contract; files: [File]; user: any }) {
      console.log(payload.files);
      let documents: DocumentDto[] = [];
      for (let file of payload.files) {
        const uploaded = (await dispatch(Actions.uploadContract, {
          user: payload.user,
          file: file,
          contract: payload.contract,
        })) as ManagedUpload.SendData;

        documents.push({
          fileName: file.name,
          path: uploaded.Key,
          key: "document",
        });
      }
      return documents;
    },

    async [Actions.addFiles]({ dispatch }, payload: { contract: Contract; files: [File]; user: any }) {
      let documents: DocumentDto[] = await dispatch(Actions.uploadMultiple, {
        contract: payload.contract,
        user: payload.user,
        files: payload.files,
      });
      const old = payload.contract;
      old.documents = old.documents?.concat(documents);
      const contract = (await dispatch(Actions.update, {
        contract: {
          ...old,
        },
      })) as Contract;
      return contract;
    },

    async [Actions.deleteFile]({ dispatch }, payload: { contract: Contract; file: DocumentDto }) {
      const contract = payload.contract;
      let removeIndex = contract.documents?.findIndex((d) => d.path === payload.file.path)!;
      const removedDocs = contract.documents?.splice(removeIndex, 1);

      await dispatch(Actions.update, {
        contract,
      });
      const s3 = new AWS.S3({});

      for (let removedDoc of removedDocs!) {
        await s3
          .deleteObject({
            Bucket: process.env.VUE_APP_USER_BUCKET!,
            Key: removedDoc.path!,
          })
          .promise();
      }

      return contract;
    },

    async [Actions.createContract](
      { dispatch, commit },
      payload: { files: File[]; changeAutomatically: boolean; contractType: ContractType }
    ) {
      const createContract: Contract = new Contract();
      createContract.changeAutomatically = payload.changeAutomatically;
      createContract.contractType = payload.contractType;
      let contract = (await contractResource.post<Contract, AxiosResponse<Contract>>("", createContract)).data;

      let documents: DocumentDto[] = await dispatch(Actions.uploadMultiple, { contract, files: payload.files });
      console.log(documents);
      contract.documents = documents;
      contract = (await dispatch(Actions.update, {
        contract: {
          ...contract,
        },
      })) as Contract;

      commit("addContract", contract);
      commit("setEditContractId", contract.ID);
      return contract;
    },

    async [Actions.update]({ dispatch, commit }, payload: { contract: Contract; state: ContractState }) {
      let contract = (await contractResource.put<Contract, AxiosResponse<Contract>>("", payload.contract)).data;

      if (payload.state) {
        contract = ((await dispatch(Actions.setContractState, {
          contract,
          state: payload.state,
        })) as ContractStateTransition).currentState;
      }
      commit("update", contract);
      return contract;
    },

    async [Actions.setContractState](
      { commit },
      payload: { contract: Contract; state: ContractState }
    ): Promise<ContractStateTransition> {
      const contractStateTransition = (
        await contractResource.post<Contract, AxiosResponse<{ currentState: Contract; previousState: Contract }>>(
          `${payload.contract.ID}/set-state/${payload.state}`
        )
      ).data;

      if (contractStateTransition.currentState.ID !== contractStateTransition.previousState.ID) {
        commit("addContract", contractStateTransition.currentState);
        commit("update", contractStateTransition.previousState);
        commit(Mutations.setEditContractId, contractStateTransition.currentState.ID);
      } else if (contractStateTransition.currentState.contractState === ContractState.ARCHIVED) {
        commit("delete", contractStateTransition.currentState);
      } else {
        commit("update", contractStateTransition.currentState);
      }

      return contractStateTransition;
    },

    async [Actions.updateChangeOptions](
      { dispatch },
      payload: { contract: Contract; changeOption: ContractChangeOption }
    ) {
      const update = { ...payload.contract };
      if (update.changeOptions) {
        if (payload.changeOption.ID) {
          const updateIndex = update.changeOptions.findIndex((c) => c.ID == payload.changeOption.ID);
          update.changeOptions[updateIndex] = payload.changeOption;
        } else {
          update.changeOptions.push({ ...payload.changeOption, ID: uuid() });
        }
      } else {
        update.changeOptions = [{ ...payload.changeOption, ID: uuid() }];
      }
      await dispatch(Actions.update, { contract: update });
    },

    async [Actions.deleteChangeOption](
      { dispatch },
      payload: { contract: Contract; changeOption: ContractChangeOption }
    ) {
      const update = { ...payload.contract };
      const delIndex = update.changeOptions?.findIndex((opt) => opt.ID === payload.changeOption.ID);
      update.changeOptions?.splice(delIndex!, 1);
      await dispatch(Actions.update, { contract: update });
    },

    async [Actions.selectChangeOption](
      { commit },
      payload: { contract: Contract; changeOption: ContractChangeOption }
    ) {
      const contract = (
        await contractResource.post<Contract, AxiosResponse<Contract>>(
          `/${payload.contract.ID}/select-change-option/${payload.changeOption.ID}`
        )
      ).data;
      console.log(contract);
      commit("update", contract);
      return contract;
    },

    [Actions.addAdditionalMeter]({ commit }, contract: Contract) {
      if (!contract.additionalMeters) {
        contract.additionalMeters = [
          {
            meterNumber: "",
            usagePerYear: 0,
          },
        ];
      } else {
        contract.additionalMeters.push({
          meterNumber: "",
          usagePerYear: 0,
        });
      }
      console.log(contract);
      commit("update", contract);
    },

    [Actions.removeAdditionalMeter]({ commit }, payload: { contract: Contract; index: number }) {
      console.log(payload.index);
      payload.contract.additionalMeters?.splice(payload.index, 1);
      console.log(payload.contract);
      commit("update", payload.contract);
    },

    async [Actions.lock](_, contract: Contract) {
      return (await contractResource.post(`/${contract.ID}/lock`)).data as Contract;
    },

    async [Actions.unlock](_, contract: Contract) {
      return (await contractResource.post(`/${contract.ID}/unlock`)).data as Contract;
    },
  },
  getters: {
    [Getters.contracts]: (state: ContractModuleState) =>
      state.data.filter((c) => c.contractState !== ContractState.ARCHIVED),
    [Getters.archivedContracts]: (state: ContractModuleState) =>
      state.data.filter((c) => c.contractState === ContractState.ARCHIVED),
    [Getters.contractById]: (state: ContractModuleState) => (id: string) => state.data.find((ct) => ct.ID === id),
    [Getters.currentContract]: (state: ContractModuleState) => state.data.find((ct) => ct.ID === state.editContractId),
    [Getters.progress]: (state: ContractModuleState) => state.progress,
    [Getters.contractsByType]: (state: ContractModuleState) => (type: ContractType) => {
      return state.data
        .filter((c) => c.contractType === type)
        .sort((c1, c2) => c1.createdAt!.localeCompare(c2.createdAt!));
    },
    [Getters.contractStateIndex]: (state: ContractModuleState) => (state: ContractState) => {
      return ContractStateIndex[state];
    },
    [Getters.savings]: (state: ContractModuleState) => {
      if (state.data.length === 0) return 0;

      return state.data
        .filter(
          (c) =>
            c.contractState !== ContractState.ARCHIVED &&
            c.contractState !== ContractState.CONTRACT_CHANGED &&
            c.changeOptions &&
            c.changeOptions.length > 0
        )
        .map((c) => {
          const cheapestChangeOption = c.changeOptions?.sort((a, b) => a.totalPricePerYear! - b.totalPricePerYear!)[0];
          if (!cheapestChangeOption) {
            return 0;
          }
          return c.totalPricePerYear! - cheapestChangeOption.totalPricePerYear!;
        })
        .reduce((prev, cur) => prev + cur, 0);
    },
    [Getters.overallCost]: (state: ContractModuleState) =>
      state.data.length > 0
        ? state.data
            .filter(
              (c) => c.contractState !== ContractState.ARCHIVED && c.contractState !== ContractState.CONTRACT_CHANGED
            )
            .map((contract: Contract) => contract.totalPricePerYear!)
            .reduce((prev, cur) => prev + cur)
        : 0,
  },
};
