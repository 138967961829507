import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import App from "./App.vue";
import "@/components/base";
import router from "./router";
import store from "./store";
import "./plugins/validate";
import "./plugins/base";
import vuetify from "./plugins/vuetify";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueCurrencyFilter from "vue-currency-filter";
import i18n from "./plugins/i18n";

// @ts-ignore
import VueMask from "v-mask";
import BaseLoaderComponent from "./components/base/BaseLoaderComponent";
Vue.use(VueMask, {
  placeholders: {
    A: null,
    "?": /\w/,
  },
});
Vue.config.productionTip = false;

Vue.use(require("vue-moment"));
Vue.use(AsyncComputed);

Vue.use(VueCurrencyFilter, {
  symbol: "€",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});

i18n.locale = "de";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("BaseLoaderComponent", BaseLoaderComponent);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
